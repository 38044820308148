import React from "react";
import { useParams } from "react-router-dom";
import Input from "../../Forms/Input/Input";
import FormButton from "../FormButton/FormButton";
import FormPage from "../FormPage/FormPage";
import HeaderExample from "../HeaderExample/HeaderExample";
import CardExample from "../CardExample/CardExample";
import WordsExample from "../WordsExample/WordsExample";
import {
  // regexLatin,
  // regexRus,
  // regCoverTitleEn,
  // regCoverTitle,
  // regexLink,
} from "../../../utils/regexFront";
import {
  getCountry,
  updateCountry,
  deleteCountry,
  getContinents,
} from "../../../utils/API";
import { checkFormValidity } from "../../../utils/functions";
import { useHistory } from "react-router-dom";

function CountryForm(props) {
  const { currentCode } = useParams();
  const history = useHistory();
  const [countryCode, setCountryCode] = React.useState({});
  const [countryContinent, setContinent] = React.useState({});
  const [countryTitle, setCountryTitle] = React.useState({});
  const [countryTitleEn, setCountryTitleEn] = React.useState({});
  const [coverTitle, setCoverTitle] = React.useState({});
  const [coverTitleEn, setCoverTitleEn] = React.useState({});
  const [countryPic, setCountryPic] = React.useState({});
  const [coverPic, setCoverPic] = React.useState({});
  const [country, setCountry] = React.useState({});
  const [formValid, setFormValidity] = React.useState(false);
  const [continents, setContinents] = React.useState([]);

  React.useEffect(() => {
    getContinents()
      .then((res) => {
        setContinents(Array.from(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  React.useEffect(() => {
    getCountry(currentCode)
      .then((res) => {
        res.data === null || res.data === undefined
          ? props.handleNotFound()
          : setCountry(res.data);
        setCountryCode({ value: res.data.code, validity: true });
        setContinent({ value: res.data.continent, validity: true });
        setCountryTitle({ value: res.data.title, validity: true });
        setCountryTitleEn({ value: res.data.titleEn, validity: true });
        setCoverTitle({ value: res.data.coverTitle, validity: true });
        setCoverTitleEn({ value: res.data.coverTitleEn, validity: true });
        setCountryPic({ value: res.data.picture, validity: true });
        setCoverPic({ value: res.data.coverPic, validity: true });
      })
      .then(() => props.restrictDel())
      .catch((err) => {
        err === 404 ? props.handleNotFound() : console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCode]);

  function handleSubmit(e) {
    e.preventDefault();
    updateCountry(currentCode, country)
      .then((res) => {
        history.goBack();
      })
      .catch((err) => {
        console.log(err);
        props.onErr(err.message);
      });
  }

  function handleClose(e) {
    history.goBack();
  }

  function handleDelete(e) {
    e.preventDefault();
    props.isDelAllowed
      ? getCountry(currentCode)
          .then((res) =>
            res.data === null || res.data === undefined
              ? props.handleNotFound()
              : deleteCountry(res.data.code).then(() => {
                  history.push("/admin");
                })
          )
          .then(() => {
            props.restrictDel();
          })

          .catch((err) => {
            console.log(err);
          })
      : props.onDeleteClick();
  }

  function makeNewContinent() {
    history.push("/admin/forms/continent/add");
  }

  React.useEffect(
    () => {
      setCountry({
        title: countryTitle.value,
        titleEn: countryTitleEn.value,
        coverTitle: coverTitle.value,
        coverTitleEn: coverTitleEn.value,
        picture: countryPic.value,
        coverPic: coverPic.value,
        continent: countryContinent.value,
      });

      const inputs = [
        countryTitle,
        countryTitleEn,
        coverTitle,
        coverTitleEn,
        countryPic,
        coverPic,
        countryContinent,
      ];

      setFormValidity(checkFormValidity(inputs));
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      countryTitle,
      countryTitleEn,
      coverTitle,
      coverTitleEn,
      countryPic,
      coverPic,
    ]
  );

  return (
    <FormPage name={countryTitle.value}>
      <p className="form__message">
        Удаление {props.isDelAllowed ? "разрешено" : "запрещено"}
      </p>
      <div className="form__rowwrap form__buttonrow">
        <FormButton
          danger={true}
          handleClick={handleDelete}
          title="Удалить"
          isActive={true}
        />
        <FormButton handleClick={handleClose} title="Закрыть" isActive={true} />
        <FormButton
          handleClick={handleSubmit}
          title="Обновить"
          isActive={formValid}
        />
      </div>
      <div className="form__rowwrap">
        <Input
          title="Код"
          select={false}
          type="string"
          id="countryCode"
          name="countryCode"
          size="S"
          value={currentCode}
          blocked="true"
          item={countryCode}
          required={true}
        />

        <Input
          title="Континент"
          select={true}
          selectionArray={continents}
          type="text"
          id="country"
          name="country"
          size="M"
          placeholder=""
          value={countryContinent.value}
          item={countryContinent}
          setItem={setContinent}
          required={true}
          handleNew={makeNewContinent}
        />
      </div>
      <div className="form__rowwrap">
        <Input
          title="Название по-русски"
          select={false}
          type="text"
          id="countryTitle"
          name="counryTitle"
          size="XL"
          placeholder="50 символов"
          value={countryTitle.value}
          item={countryTitle}
          setItem={setCountryTitle}
          required={true}
          // pattern={regexRus}
          errMessage={"2-50"}
        />
        <Input
          title="Краткое название"
          select={false}
          type="text"
          id="coverTitle"
          name="coverTitle"
          size="L"
          placeholder="20 символов"
          value={coverTitle.value}
          item={coverTitle}
          setItem={setCoverTitle}
          required={true}
          // pattern={regCoverTitle}
          errMessage={"2-25"}
        />
      </div>

      <div className="form__rowwrap">
        <Input
          title="English title"
          select={false}
          type="text"
          id="tripTitleEn"
          name="tripTitleEn"
          size="XL"
          value={countryTitleEn.value}
          item={countryTitleEn}
          setItem={setCountryTitleEn}
          required={false}
          // pattern={regexLatin}
          errMessage={"2-50"}
        />
        <Input
          title="Short"
          select={false}
          type="text"
          id="coverTitleEn"
          name="coverTitleEn"
          size="L"
          value={coverTitleEn.value}
          item={coverTitleEn}
          setItem={setCoverTitleEn}
          required={false}
          // pattern={regCoverTitleEn}
          errMessage={"2-25"}
        />
      </div>

      <div className="form__rowwrap ">
        <Input
          title="Титульное фото"
          select={false}
          type="string"
          id="countryPicture"
          name="countryPicture"
          size="XXL"
          value={countryPic.value}
          item={countryPic}
          setItem={setCountryPic}
          required={false}
          // pattern={regexLink}
          errMessage={"ссылка на фото"}
        />
      </div>
      <div className="form__rowwrap ">
        <Input
          title="Заглавное фото"
          select={false}
          type="string"
          id="coverPic"
          name="coverPic"
          size="XXL"
          value={coverPic.value}
          item={coverPic}
          setItem={setCoverPic}
          required={false}
          // pattern={regexLink}
          errMessage={"ссылка на фото"}
        />
      </div>

      <HeaderExample pic={countryPic.value} words={countryTitle.value} />

      <CardExample pic={coverPic.value} words={coverTitle.value} />

      <WordsExample
        items={[
          countryCode.value,
          countryContinent.value,
          countryTitle.value,
          countryTitleEn.value,
          coverTitle.value,
          coverTitleEn.value,
        ]}
      />
    </FormPage>
  );
}

export default CountryForm;
