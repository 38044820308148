import React from "react";
import Header from "../Header/Header";
import ContinentsNavigation from "../ContinentsNavigation/ContinentsNavigation";
import { useParams } from "react-router-dom";
import { TripsContext } from "../../context/TripsContext";
import { HashtagsContext } from "../../context/HashtagsContext";

import Statistics from "../Statistics/Statistics";
import Photogrid from "../Photo-grid/Photo-grid";
import { PIC_PATH, DB_base } from "../../utils/constants";

function Hashtag(props) {
  const { hashtagCode } = useParams();
  const [hashtag, setHashtag] = React.useState("");
  const [hashtagTrips, setHashtagTrips] = React.useState("");
  const trips = React.useContext(TripsContext);
  const hashtags = React.useContext(HashtagsContext);
  const picPath = hashtag.picture
    ? hashtag.picture.replace(DB_base, PIC_PATH)
    : "";

  React.useEffect(() => {
    if (hashtags.length > 0 & trips.length > 0)
    {if (hashtags.find((tag) => tag.code === hashtagCode)) {
      setHashtag(hashtags.find((tag) => tag.code === hashtagCode));
      setHashtagTrips(
        trips.filter((trip) => trip.hashtags.includes(hashtagCode))
      );
      props.handleFind();
    } else {
      props.handleNotFound();
    }}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hashtags, trips]);

  return (
    <div className="country">
      <Header headerImage={picPath} title={hashtag.coverTitle} />
      <ContinentsNavigation path={"../"} />
      <Statistics
        statistics1number={hashtagTrips.length}
        statistics1text="trips"
      />
      <Photogrid
        cards={Array.from(hashtagTrips)}
        path={"../stories/"}
        path2={"01"}
        // это про заполнение карточки, true/false
        date={false}
        month={false}
        yearMonth={false}
        trips={false}
        stories={false}
      />
    </div>
  );
}

export default Hashtag;
