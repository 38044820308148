import React from "react";
import { Link } from "react-router-dom";

import FormButton from "../../Forms/FormButton/FormButton";
import FormLink from "../../Forms/FormLink/FormLink";
import { useParams } from "react-router-dom";
import { getTrip, getStories } from "../../../utils/API";
import { BASE_URL } from "../../../utils/constants";
import { useHistory } from "react-router-dom";
import { formatDate } from "../../../utils/functions";

function AdminTrip(props) {
  const history = useHistory();
  const { currentCode } = useParams();
  const [trip, setTrip] = React.useState({});
  const [tripStories, setTripStories] = React.useState({});

  function handleClose(e) {
    history.goBack();
  }

  React.useEffect(() => {
    console.log(currentCode);

    getTrip(currentCode)
      .then((res) => {
        setTrip(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    getStories()
      .then((res) => {
        return res;
      })
      .then((res) =>
        setTripStories(
          Array.from(res.data).filter((story) => story.trip === trip.code)
        )
      );
  }, [trip]);

  //   React.useEffect(() => {
  //     console.log(tripStories);
  //   }, [tripStories]);

  return (
    <div className="admin__page">
      <h2 className="admin__title">{trip.title}</h2>
      <div className="form__rowwrap form__buttonrow">
        <FormLink
          base={BASE_URL}
          route={`/admin/forms/trips/${trip.code}`}
          name="Редактировать"
        />

        <FormLink
          base={BASE_URL}
          route={`/admin/forms/story/add`}
          name="Новая история"
        />

        <FormButton
          danger={false}
          isActive={true}
          title="Обратно"
          handleClick={handleClose}
        />
      </div>

      <div className="admin__table">
        {Array.from(tripStories).map((story, i) => {
          return (
            // <a
            //   href={`${BASE_URL}/admin/forms/stories/${story.code}`}
            //   className="item__title"
            // >
            //   День {story.dayNumber}, {formatDate(story.date)}, {story.title}
            // </a>

            <Link
              to={`/admin/forms/stories/${story.code}`}
              className="item__title"
            >
              День {story.dayNumber}, {formatDate(story.date)}, {story.title}
            </Link>
          );
        })}
      </div>
    </div>
  );
}

export default AdminTrip;
