import { Link } from "react-router-dom";

function SiteNavigation() {
  return (
    <nav className="site-navigation__links">
      <Link to="/" className="site-navigation__link">
        {" "}
        Домой
      </Link>
      <Link to="/allYears" className="site-navigation__link">
        {" "}
        По годам
      </Link>
      <Link to="/allHashtags" className="site-navigation__link">
        {" "}
        Тематики
      </Link>
      <Link to="/bestPictures" className="site-navigation__link">
        {" "}
        Лучшие 
      </Link>
      <Link to="/contacts" className="site-navigation__link">
        Контакты
      </Link>
    </nav>
  );
}

export default SiteNavigation;
