import React from "react";
import Input from "../../Forms/Input/Input";
import { useParams } from "react-router-dom";
import FormButton from "../FormButton/FormButton";
import FormPage from "../FormPage/FormPage";
import CardExample from "../CardExample/CardExample";
import WordsExample from "../WordsExample/WordsExample";
import { useHistory } from "react-router-dom";
import {
  getTrip,
  getCountries,
  getHashtags,
  updateTrip,
  deleteTrip,
} from "../../../utils/API";

import {
  // regexLatin,
  // regexRus,
  // regCoverTitleEn,
  // regCoverTitle,
  // regTripCode,
  regexDate,
} from "../../../utils/regexFront";

import { checkFormValidity, formatDate } from "../../../utils/functions";

function TripForm(props) {
  const { currentCode } = useParams();
  const history = useHistory();

  const [tripCode, setTripCode] = React.useState({});
  const [tripTitle, setTripTitle] = React.useState({});
  const [tripTitleEn, setTripTitleEn] = React.useState({});
  const [coverTitle, setCoverTitle] = React.useState({});
  const [coverTitleEn, setCoverTitleEn] = React.useState({});
  const [picsPath, setPicsPath] = React.useState({});
  const [coverPic, setCoverPic] = React.useState({});
  const [tripCountries, setTripCountries] = React.useState([]);
  const [tripHashtags, setTripHashtags] = React.useState([]);
  const [issueDate, setIssueDate] = React.useState([]);
  const [trip, setTrip] = React.useState({});
  const [formValid, setFormValidity] = React.useState(false);
  const [countries, setCountries] = React.useState([]);
  const [hashtags, setHashtags] = React.useState([]);

  function handleSubmit(e) {
    e.preventDefault();
    updateTrip(currentCode, trip)
      .then((res) => {
        history.goBack();
      })
      .catch((err) => {
        console.log(err);
        props.onErr(err.message);
      });
  }

  function handleClose(e) {
    history.goBack();
  }

  function handleDelete(e) {
    e.preventDefault();
    props.isDelAllowed
      ? getTrip(currentCode)
          .then((res) =>
            res.data === null || res.data === undefined
              ? props.handleNotFound()
              : deleteTrip(res.data.code).then(() => {
                  history.goBack();
                })
          )
          .then(() => {
            props.restrictDel();
          })
          .catch((err) => {
            console.log(err);
          })
      : props.onDeleteClick();
  }

  function makeNewCountry() {
    history.push("/admin/forms/country/add");
  }

  function makeNewHashtag() {
    history.push("/admin/forms/hashtags/add");
  }

  React.useEffect(() => {
    getCountries()
      .then((res) => {
        const sortedCountries = Array.from(res.data).sort(function (a, b) {
          return a.title > b.title ? 1 : -1;
        });
        setCountries(Array.from(sortedCountries));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  React.useEffect(() => {
    getHashtags()
      .then((res) => {
        const sortedHashtags = Array.from(res.data).sort(function (a, b) {
          return a.title > b.title ? 1 : -1;
        });
        setHashtags(Array.from(sortedHashtags));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  React.useEffect(() => {
    getTrip(currentCode)
      .then((res) => {
        res.data === null || res.data === undefined
          ? props.handleNotFound()
          : setTrip(res.data);
        setTripCode({ value: res.data.code, validity: true });
        setTripTitle({ value: res.data.title, validity: true });
        setTripTitleEn({ value: res.data.titleEn, validity: true });
        setCoverTitle({ value: res.data.coverTitle, validity: true });
        setCoverTitleEn({ value: res.data.coverTitleEn, validity: true });
        setPicsPath({ value: res.data.picsPath, validity: true });
        setCoverPic({ value: res.data.coverPic, validity: true });
        setTripCountries({ value: res.data.countries, validity: true });
        setTripHashtags({ value: res.data.hashtags, validity: true });
        setIssueDate({ value: formatDate(res.data.issueDate), validity: true });
      })
      .then(() => props.restrictDel())
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCode]);

  React.useEffect(() => {
    setTrip({
      code: tripCode.value,
      title: tripTitle.value,
      titleEn: tripTitleEn.value,
      coverTitle: coverTitle.value,
      coverTitleEn: coverTitleEn.value,
      // picsPath: picsPath.value,
      coverPic: coverPic.value,
      countries: tripCountries.value,
      hashtags: tripHashtags.value,
      issueDate: issueDate.value,
    });

    const inputs = [
      tripCode,
      tripTitle,
      tripTitleEn,
      coverTitle,
      coverTitleEn,
      // picsPath,
      coverPic,
      tripCountries,
      tripHashtags,
      issueDate,
    ];

    setFormValidity(checkFormValidity(inputs));
  }, [
    tripCode,
    tripTitle,
    tripTitleEn,
    coverTitle,
    coverTitleEn,
    // picsPath,
    coverPic,
    tripCountries,
    tripHashtags,
    issueDate,
  ]);

  React.useEffect(() => {
    console.log(trip);
  }, [trip]);

  return (
    <FormPage name={tripTitle.value}>
      <p className="form__message">
        Удаление {props.isDelAllowed ? "разрешено" : "запрещено"}
      </p>
      <div className="form__rowwrap form__buttonrow">
        <FormButton
          danger={true}
          handleClick={handleDelete}
          title="Удалить"
          isActive={true}
        />
        <FormButton handleClick={handleClose} title="Закрыть" isActive={true} />

        <FormButton
          isActive={formValid}
          title="Обновить"
          handleClick={handleSubmit}
        />
      </div>

      <div className="form__rowwrap">
        <Input
          title="Код"
          select={false}
          type="string"
          id="tripCode"
          name="tripCode"
          size="XS"
          value={tripCode.value}
          item={tripCode}
          setItem={setTripCode}
          required={true}
          // pattern={regTripCode}
        />

        <Input
          title="Дата выпуска"
          select={false}
          type="date"
          id="issueDate"
          name="issueDate"
          size="L"
          value={issueDate.value}
          item={issueDate}
          setItem={setIssueDate}
          required={true}
          pattern={regexDate}
          errMessage={"DD/MM/YYYY"}
        />
      </div>

      <div className="form__rowwrap">
        <Input
          title="Название по-русски"
          select={false}
          type="text"
          id="tripTitle"
          name="tripTitle"
          size="XL"
          value={tripTitle.value}
          item={tripTitle}
          setItem={setTripTitle}
          required={true}
          // pattern={regexRus}
          errMessage={"2-50"}
        />
        <Input
          title="Краткое название"
          select={false}
          type="text"
          id="coverTitle"
          name="coverTitle"
          size="M"
          placeholder="20 символов"
          value={coverTitle.value}
          item={coverTitle}
          setItem={setCoverTitle}
          required={true}
          // pattern={regCoverTitle}
          errMessage={"2-25"}
        />
      </div>

      <div className="form__rowwrap">
        <Input
          title="English title"
          select={false}
          type="text"
          id="tripTitleEn"
          name="tripTitleEn"
          size="XL"
          placeholder="50 символов"
          value={tripTitleEn.value}
          item={tripTitleEn}
          setItem={setTripTitleEn}
          required={false}
          // pattern={regexLatin}
          errMessage={"2-50"}
        />
        <Input
          title="Short"
          select={false}
          type="text"
          id="coverTitleEn"
          name="coverTitleEn"
          size="M"
          value={coverTitleEn.value}
          item={coverTitleEn}
          setItem={setCoverTitleEn}
          required={false}
          // pattern={regCoverTitleEn}
          errMessage={"2-25"}
        />
      </div>

      <div className="form__rowwrap">
        {/* <Input
          title="Путь к файлам с фото"
          select={false}
          type="url"
          id="picsPath"
          name="picsPath"
          size="XL"
          placeholder="url"
          value={picsPath.value}
          item={picsPath}
          setItem={setPicsPath}
          required={false}
          pattern={regexLink}
        /> */}
        <Input
          title="Заглавное фото"
          select={false}
          type="string"
          id="coverPic"
          name="coverPic"
          size="XXL"
          value={coverPic.value}
          item={coverPic}
          setItem={setCoverPic}
          required={false}
        />
      </div>

      <div className="form__rowwrap">
        <Input
          title="Страны"
          select={false}
          type="text"
          id="tripCountries"
          name="tripCountries"
          size="L"
          placeholder=""
          value={tripCountries.value}
          item={tripCountries}
          setItem={setTripCountries}
          required={true}
          handleNew={makeNewCountry}
        />
        <p className="form__reference">
          {Array.from(countries).map(
            (country) => `${country.title}:${country.code}  `
          )}
        </p>

        <Input
          title="Hashtags"
          select={false}
          type="text"
          id="tripHashtags"
          name="tripHashtags"
          size="L"
          placeholder=""
          value={tripHashtags.value}
          item={tripHashtags}
          setItem={setTripHashtags}
          required={false}
          handleNew={makeNewHashtag}
        />
        <p className="form__reference">
          {Array.from(hashtags).map((hashtag) => `${hashtag.code}  `)}
        </p>
      </div>
      <div className="form__rowwrap form__buttonrow">
        <FormButton
          danger={false}
          handleClick={makeNewCountry}
          title="Новая страна"
          isActive={true}
        />
        <FormButton
          handleClick={makeNewHashtag}
          title="Новый хештэг"
          isActive={true}
        />
      </div>

      <CardExample pic={`${coverPic.value}`} words={coverTitle.value} />

      <WordsExample
        items={[
          tripCode.value,
          tripTitle.value,
          tripTitleEn.value,
          coverTitle.value,
          coverTitleEn.value,
          picsPath.value,
          coverPic.value,
          tripCountries.value,
          tripHashtags.value,
          issueDate.value,
        ]}
      />
    </FormPage>
  );
}

export default TripForm;
