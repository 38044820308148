import React from "react";

function DeletePopup(props) {
  return (
    <div className={props.isOpen ? "errorAPI" : "errorAPI errorAPI_hidden"}>
      <p className="errorAPI__message">Точно удалить?</p>

      <div className="deletePopup__wrap">
        <h3 className="errorAPI__close" onClick={props.onYes}>
          Да, точно
        </h3>
        <h3 className="errorAPI__close" onClick={props.onNo}>
          Нет!!!
        </h3>
      </div>
    </div>
  );
}

export default DeletePopup;
