import React from "react";
import Header from "../Header/Header";
import ContinentsNavigation from "../ContinentsNavigation/ContinentsNavigation";
import { useParams } from "react-router-dom";
import Gridsection from "../Grid-section/Grid-section";
import { CountriesContext } from "../../context/CountriesContext";
import { TripsContext } from "../../context/TripsContext";
import { StoriesContext } from "../../context/StoriesContext";
// import { ContinentsContext } from "../../context/ContinentsContext";
import Statistics from "../Statistics/Statistics";
import { PIC_PATH, DB_base } from "../../utils/constants";

function Country(props) {
  const { countryCode } = useParams();

  const [country, setCountry] = React.useState("");
  const [countryTrips, setCountryTrips] = React.useState("");
  const [tripsNumber, setTripsNumber] = React.useState("");
  const [fullTrips, setFullTrips] = React.useState({});
  const [storiesNumber, setStoriesNumber] = React.useState("");

  const trips = React.useContext(TripsContext);
  const countries = React.useContext(CountriesContext);
  const stories = React.useContext(StoriesContext);
  // const continents = React.useContext(ContinentsContext);
  const picPath = country.picture
    ? country.picture.replace(DB_base, PIC_PATH)
    : "";

    
  React.useEffect(() => 
  {if (countries.length > 0) {
  console.log(countries)}},[countries])

  React.useEffect(() => {
    if (countries.length > 0) 
  {
    if (countries.find((country) => country.code === countryCode)) 

    {  setCountry(countries.find((country) => country.code === countryCode));
      props.handleFind()
      console.log("yes1");}
  
     else {
      props.handleNotFound();
      console.log("no1")};
  }
   else {
    props.handleNotFound();}},
  [countryCode, countries, props]);


   React.useEffect(() => {
    if (trips.length > 0 & countries.length > 0) 

    {setCountryTrips(
      trips.filter((trip) => trip.countries.includes(countryCode))
    )
    props.handleFind()
      ;}
 
   else 
   { props.handleNotFound();
    };

    window.scrollTo(0, 0)
  
    },
   [countryCode, countries, trips, props]);



  React.useEffect(() => {
    if(countryTrips.length > 0 & stories.length > 0 )
    {
      console.log(countryTrips);
      setTripsNumber(countryTrips.length);
      const unsortedList = Array.from(countryTrips).map((trip) => gatherTripData(trip))
      const sortedList = unsortedList.sort((a, b) =>
      a.date.date > b.date.date ? -1 : +1);

      setFullTrips(Array.from(countryTrips).map((trip) => gatherTripData(trip)));
      setFullTrips(sortedList)}
      
      else {props.handleNotFound()};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryTrips, stories]);

React.useEffect(() =>{console.log(fullTrips)}, [fullTrips]);

  React.useEffect(() => {
    let x = 0;
    setStoriesNumber(
      Array.from(fullTrips)
        .map((i) => (x += i.cards.length), x)
        .reverse()[0]
    );
  }, [fullTrips]);

  //   function setNotFound(){
  //     if (!country) {props.handleNotFound()}
  //   }
  //   setNotFound();

  function gatherTripData(trip) {
    const tripStories = stories.filter((story) => story.trip === trip.code);
    const tripDate = Array.from(tripStories).sort((a, b) =>
    a.date > b.date ? +1 : -1)[0]; 

    const fullTrip = {
      title: trip.title,
      cards: tripStories,
      code: trip.code,
      date: tripDate,
    };
    return fullTrip;
  }

  return (
    <div className="country">
      <Header headerImage={picPath} title={country.title} />
      <ContinentsNavigation path="../" />
      <Statistics
        statistics1number={tripsNumber}
        statistics2number={storiesNumber}
        statistics1text="trips"
        statistics2text="stories"
      />
      <Gridsection
        sections={Array.from(fullTrips)}
        date={true}
        month={false}
        yearMonth={false}
        trips={false}
        stories={false}
        path={"../stories/"}
      />
    </div>
  );
}

export default Country;
