import React from "react";
import Header from "../Header/Header";
import ContinentsNavigation from "../ContinentsNavigation/ContinentsNavigation";
import More from "../More/More";
// import { trips, countries, stories } from "../../utils/tempDB";

// import { getTrips, getCountries, getStories } from "../../utils/API";

import Statistics from "../Statistics/Statistics";
import Photogrid from "../Photo-grid/Photo-grid";
import {homePage} from "../../utils/mainPages";
import { PIC_PATH, DB_base } from "../../utils/constants";


import { TripsContext } from "../../context/TripsContext";
import { StoriesContext } from "../../context/StoriesContext";
import { CountriesContext } from "../../context/CountriesContext";

function Home(props) {
  const homePic = homePage
  ? homePage.replace(DB_base, PIC_PATH)
  : "";
  const homeTitle = "Дневник путешествий";
  const homeSubtitle = "и альбом красот";

  const trips = React.useContext(TripsContext);
  const countries = React.useContext(CountriesContext);
  const stories = React.useContext(StoriesContext);

  const [tripsNumber, setTripsNumber] = React.useState("");
  const [countriesNumber, setCountriesNumber] = React.useState("");
  const [tripsList, setTripsList] = React.useState("");
  const [openTrips, setOpenTrips] = React.useState(12);

  React.useEffect(() => {
    setTripsNumber(trips.length);
  }, [trips]);

  // React.useEffect(() => {
  //   console.log(openTrips);
  // }, [openTrips]);


  React.useEffect(() => {
    const Russia = countries.filter((country) => country.continent === "RU"); 
    setCountriesNumber(countries.length - Russia.length + 1);
  }, [countries]);

  React.useEffect(() => {
    const unsortedList = Array.from(trips).map((trip) => gatherTripData(trip));
    const sortedList = unsortedList.sort((a, b) =>
      a.issueDate > b.issueDate ? -1 : +1
    );
    setTripsList(sortedList.slice(0, openTrips));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stories, openTrips]);

  // React.useEffect(() => {
  //   console.log(tripsList);
  // }, [tripsList, stories]);

  function gatherTripData(trip) {
    const tripStories = stories.filter((story) => story.trip === trip.code);
    // console.log(new Date(trip.issueDate));
    const fullTrip = {
      code: trip.code,
      coverPic: trip.coverPic,
      coverTitle: trip.coverTitle,
      month: trip.month,
      year: trip.year,
      stories: tripStories,
      issueDate: new Date(trip.issueDate),
    };
    return fullTrip;
  }


  return (
    <div className="country">
      <Header headerImage={homePic} title={homeTitle} subtitle={homeSubtitle} />
      <ContinentsNavigation path={"/"} />
      <Statistics
        statistics1number={tripsNumber}
        statistics2number={countriesNumber}
        statistics1text="trips"
        statistics2text="countries"
      />
      <Photogrid
        title={"Новые истории"}
        cards={Array.from(tripsList)}
        // key={section.code}
        // id={section.code}
        path={`../stories/`}
        path2={"01"}
        // это про заполнение карточки, true/false
        date={false}
        month={false}
        yearMonth={true}
        trips={false}
        stories={true}
      />
      <More 
        handleClick={() => setOpenTrips(openTrips+12)}
        isActive={
          trips.length > openTrips
          ? true
          : false
        }
      />

    </div>
  );
}

export default Home;
