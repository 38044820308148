function FormButton(props) {
  return (
    <p
      className={
        props.isActive
          ? props.danger
            ? "form__button form__button_danger"
            : "form__button"
          : "form__button form__button_inactive"
      }
      onClick={
        props.isActive
          ? // props.danger
            //   ? props.handleDanger(props.handleClick)
            //   :
            props.handleClick
          : undefined
      }
    >
      {props.title}
    </p>
  );
}

export default FormButton;
