import React from "react";
import { PIC_PATH, DB_base } from "../../../utils/constants";

function CardExample(props) {
  return (
    <div className="form__rowwrap">
      <div className="form__preview">
        <div className="card form__example">
          <img
            className="card__image"
            src={props.pic ? props.pic.replace(DB_base, PIC_PATH) : ""}
            alt=""
          />
          <div className="card__words">
            <h3 className="card__title">{props.words}</h3>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardExample;
