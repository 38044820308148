import React from "react";
import Header from "../Header/Header";
import ContinentsNavigation from "../ContinentsNavigation/ContinentsNavigation";
// import { hashtags } from "../../utils/tempDB";
// import homePath from "../../images/IR0107031.jpg";
import Photogrid from "../Photo-grid/Photo-grid";
import { HashtagsContext } from "../../context/HashtagsContext";
import {themesPage} from "../../utils/mainPages";
import { PIC_PATH, DB_base } from "../../utils/constants";


function AllHashtags(props) {
  // сделать библиотеку картинок для главных страниц
  const homePic = themesPage
  ? themesPage.replace(DB_base, PIC_PATH)
  : "";
  const homeTitle = "Тематики";
  const hashtags = React.useContext(HashtagsContext);

  // React.useEffect(() => {
  //   console.log(hashtags);
  // });

  return (
    <div className="country">
      <Header headerImage={homePic} title={homeTitle} />
      <ContinentsNavigation path={"../"} />
      <Photogrid
        cards={Array.from(hashtags)}
        date={false}
        month={false}
        yearMonth={false}
        trips={false}
        stories={false}
        path={"../hashtags/"}
      />
    </div>
  );
}

export default AllHashtags;
