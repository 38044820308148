import { PIC_PATH, DB_base } from "../../utils/constants";

function HorizontalSmallPics(props) {
  return (
    <div className="small-pictures__grid_h">
      {Array.from(props.cards).map((card, i) => {
        const handleClick = () => {
          props.onCardClick(card);
        };

        return (
          <div className="best-pic">
            <img
              className="small-pic__img small-pic__img_h"
              src={card.link ? card.link.replace(DB_base, PIC_PATH) : ""}
              alt=""
              onClick={handleClick}
            />
          </div>
        );
      })}
    </div>
  );
}

export default HorizontalSmallPics;
