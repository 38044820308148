export const WIDESCREEN = {
  horizontal: 3,
  vertical: 4,
  minwidth: 900,
};

export const SMALLSCREEN = {
  horizontal: 2,
  vertical: 3,
  minwidth: 500,
};

// export const BASE_URL = "http://localhost:3001";
// export const BASE_URL_API = "http://localhost:3000";

// export const BASE_URL = "http://zabotkins.ru";
// export const BASE_URL_API = "http://zabotkins.ru/api";

export const BASE_URL = "https://myfriendthewind.ru";
export const BASE_URL_API = "https://myfriendthewind.ru/api";


export const PIC_PATH = "https://myfriendthewind.ru/images/";

// export const PIC_PATH = "http://zabotkins.ru/images/";

export const DB_base = "path/";
