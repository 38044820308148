import React from "react";
// import { bestPictures } from "../../utils/tempDB";
import SiteNavigation from "../SiteNavigation/SiteNavigation";
import HorizontalPicsGrid from "../HorizontalPicsGrid/HorizontalPicsGrid";
import VerticalPicsGrid from "../VerticalPicsGrid/VerticalPicsGrid";
import { WIDESCREEN, SMALLSCREEN } from "../../utils/constants";
import { BestPicsContext } from "../../context/BestPicsContext";
import { PictagsContext } from "../../context/PictagsContext";
import { CountriesContext } from "../../context/CountriesContext";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

function BestPictures(props) {
  const { tagCode } = useParams();
  const bestPictures = React.useContext(BestPicsContext);
  const [bestPicsList, setPicsList] = React.useState([]);
  const pictags = React.useContext(PictagsContext);
  const countries = React.useContext(CountriesContext);
  const [width, setWidth] = React.useState("");
  // const [basePicsList, setBase] = React.useState(bestPictures);
  const [hBestpics, setHBestPics] = React.useState("");
  const [vBestpics, setVBestPics] = React.useState("");
  const [hRow, setHRow] = React.useState(3);
  const [vRow, setVRow] = React.useState(4);
  const [picGroups, setGroups] = React.useState("");
  const [tag, setPictag] = React.useState("");
  const [tagTitle, setTitle] = React.useState("");

  const shuffle = (arr) =>
    arr.sort(function () {
      return Math.random() - 0.5;
    });

  const handleResize = () => {
    setTimeout(setWidth([window.innerWidth]), 500);
  };

  // const handleShuffle = () => {
  //   setBase(shuffle(basePicsList));
  // }

  React.useEffect(() => {
    // console.log(tagCode);
    setPicsList(
      tagCode
        ? bestPictures.filter((item) => item.hashtags.includes(tagCode))
        : bestPictures
    );

    setPictag(
      tagCode
      ? 
        pictags.find((pictag) => pictag.code === tagCode)
      : undefined
    )

    window.scrollTo(0, 0)

  }, [bestPictures, tagCode, pictags]);


  React.useEffect(() => {
   setTitle(
    tag
    ? tag.title
    : "Мои любимые фотографии")
  }, [tag])

  React.useEffect(() => {
    // eslint-disable-next-line array-callback-return
    bestPicsList.map((pic) => {
      const countryTitle = countries.find((item) => item.code === pic.country);
      pic.countryTitle = countryTitle.title;
    })
  }, [bestPicsList, countries]);

  

  React.useEffect(() => {
    // console.log(bestPictures);
    const shuffledBP = shuffle(bestPicsList);
    setHBestPics(shuffledBP.filter((pic) => pic.orientation === "H"));
    setVBestPics(shuffledBP.filter((pic) => pic.orientation === "V"));
    setWidth(window.innerWidth);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bestPicsList]);

  // React.useEffect(() => {
  //   console.log(hBestpics)},[hBestpics]);

  React.useEffect(() => {
    setGroups(preparePics());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hBestpics, vBestpics, hRow, vRow]);

  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  React.useEffect(() => {
    setHRow(
      width > WIDESCREEN.minwidth
        ? WIDESCREEN.horizontal
        : SMALLSCREEN.horizontal
    );
    setVRow(
      width > WIDESCREEN.minwidth ? WIDESCREEN.vertical : SMALLSCREEN.vertical
    );
  }, [width]);

  const preparePics = () => {
    let groups = [];
    let turns = Math.max(hBestpics.length / hRow, vBestpics.length / vRow);

    for (let i = 1; i <= turns + 1; i++) {
      const row1 =
        // hBestpics.length >= hRow*(i-1) ?
        {
          slice: hBestpics.slice(hRow * (i - 1), hRow * i),
          type: "H",
          number: i * 2 - 1,
        };
      // : undefined;

      if (row1) {
        groups.push(row1);
      }

      const row2 =
        // vBestpics.length >= vRow*i?
        {
          slice: vBestpics.slice(vRow * (i - 1), vRow * i),
          type: "V",
          number: i * 2,
        };
      //  : undefined;

      if (row2) {
        groups.push(row2);
      }
    }

    return groups;
  };

  return (
    <>
      <SiteNavigation />
      <div className="best-pictures">
        <h2 className="best-pictures__title">{tagTitle}</h2>
        {/* <p className="best-pictures__shuffle" onClick={handleShuffle}>Перемешать</p> */}

        <nav className="tag__links">
          {Array.from(pictags).map((tag, i) => {
            return (
              <Link
                to={`../bestpics/${tag.code}`}
                className="tag__link"
                onClick={props.onClose}
              >
                {tag.title}
              </Link>
            );
          })}

          <Link
            to={`../bestPictures`}
            className="tag__link"
            onClick={props.onClose}
          >
            Все
          </Link>
        </nav>

        {Array.from(picGroups).map((group, i) =>
          group.type === "H" ? (
            <HorizontalPicsGrid
              cards={group.slice}
              key={group.number}
              onCardClick={props.onCardClick}
            />
          ) : group.type === "V" ? (
            <VerticalPicsGrid
              cards={group.slice}
              key={group.number}
              onCardClick={props.onCardClick}
            />
          ) : undefined
        )}
      </div>
    </>
  );
}

export default BestPictures;
