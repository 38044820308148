import { Link } from "react-router-dom";

function TripNavigation(props) {
  return (
    <nav className="trip-navigation">
      {props.yesterday ? (
        <Link
          to={`/stories/${props.yesterday.code}`}
          className="trip-navigation__link trip-navigation__link_left"
        >
          Вчера
        </Link>
      ) : (
        <div></div>
      )}

      {props.tomorrow ? (
        <Link
          to={`/stories/${props.tomorrow.code}`}
          className="trip-navigation__link trip-navigation__link_right"
        >
          Завтра
        </Link>
      ) : undefined}
    </nav>
  );
}

export default TripNavigation;
