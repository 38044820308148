function FormError(props) {
  return (
    <div className={props.isOpen ? "errorAPI" : "errorAPI errorAPI_hidden"}>
      <p className="errorAPI__message">{props.message}</p>

      <h3 className="errorAPI__close" onClick={props.onClose}>
        Закрыть
      </h3>
    </div>
  );
}

export default FormError;
