import React from "react";
import Input from "../../Forms/Input/Input";
import FormButton from "../FormButton/FormButton";
import FormPage from "../FormPage/FormPage";
import { useHistory } from "react-router-dom";
import { createHashtag } from "../../../utils/API";
import { checkFormValidity } from "../../../utils/functions";
import CardExample from "../CardExample/CardExample";
import WordsExample from "../WordsExample/WordsExample";
import HeaderExample from "../HeaderExample/HeaderExample";

function NewHashtagForm(props) {
  const [tagCode, setTagCode] = React.useState({});
  const [coverTitle, setCoverTitle] = React.useState({});
  const [coverTitleEn, setCoverTitleEn] = React.useState({});
  const [coverPic, setCoverPic] = React.useState({});
  const [headerPic, setHeaderPic] = React.useState({});
  const [hashtag, setHashtag] = React.useState({});
  const [formValid, setFormValidity] = React.useState(false);

  const history = useHistory();

  function handleSubmit(e) {
    e.preventDefault();
    createHashtag(hashtag)
      .then((res) => {
        history.goBack();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleClose(e) {
    history.goBack();
  }

  React.useEffect(() => {
    setHashtag({
      code: tagCode.value,
      coverTitle: coverTitle.value,
      coverTitleEn: coverTitleEn.value,
      coverPic: coverPic.value,
      picture: headerPic.value,
    });

    const inputs = [tagCode, coverTitle, coverTitleEn, coverPic, headerPic];

    setFormValidity(checkFormValidity(inputs));
  }, [tagCode, coverTitle, coverTitleEn, coverPic, headerPic]);

  return (
    <FormPage name="Тематики и тэги">
      <div className="form__rowwrap form__buttonrow">
        <FormButton handleClick={handleClose} title="Закрыть" isActive={true} />
        <FormButton
          handleClick={handleSubmit}
          title="Сохранить"
          isActive={formValid}
        />
      </div>

      <div className="form__rowwrap">
        <Input
          title="Код"
          select={false}
          type="string"
          id="tagCode"
          name="tagCode"
          size="M"
          value={tagCode.value}
          item={tagCode}
          setItem={setTagCode}
          required={true}
        />
        <Input
          title="Краткое название"
          select={false}
          type="text"
          id="coverTitle"
          name="coverTitle"
          size="M"
          placeholder="20 символов"
          value={coverTitle.value}
          item={coverTitle}
          setItem={setCoverTitle}
          required={true}
        />
        <Input
          title="Short name"
          select={false}
          type="text"
          id="coverTitleEn"
          name="coverTitleEn"
          size="M"
          placeholder="20 символов"
          value={coverTitleEn.value}
          item={coverTitleEn}
          setItem={setCoverTitleEn}
          required={true}
        />
      </div>
      <div className="form__rowwrap">
        <Input
          title="Заглавное фото"
          select={false}
          type="string"
          id="coverPic"
          name="headerPic"
          size="XL"
          value={headerPic.value}
          item={headerPic}
          setItem={setHeaderPic}
          required={false}
        />

        <Input
          title="Титульное фото"
          select={false}
          type="string"
          id="coverPic"
          name="coverPic"
          size="XL"
          value={coverPic.value}
          item={coverPic}
          setItem={setCoverPic}
          required={false}
        />
      </div>

      <HeaderExample pic={headerPic.value} words={coverTitle.value} />

      <CardExample pic={`${coverPic.value}`} words={coverTitle.value} />

      <WordsExample
        items={[tagCode.value, coverTitle.value, coverTitleEn.value]}
      />
    </FormPage>
  );
}

export default NewHashtagForm;
