import React from "react";
import FormButton from "../../Forms/FormButton/FormButton";
import FormLink from "../../Forms/FormLink/FormLink";
import { getPics } from "../../../utils/API";
import { BASE_URL } from "../../../utils/constants";
import { useHistory } from "react-router-dom";
import BestPicsItem from "../BestPicsItem/BestPicsItem";

function AdminBestPics(props) {
  const history = useHistory();
  const [bestpics, setPics] = React.useState([]);

  function handleClose(e) {
    history.goBack();
  }

  React.useEffect(() => {
    getPics()
      .then((res) => setPics(Array.from(res.data)))
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function sortByCountries() {
    const sortedPics = Array.from(bestpics).sort(function (a, b) {
      return a.country > b.country ? 1 : -1;
    });

    setPics(sortedPics);
  }

  function sortByYears() {
    const sortedPics = Array.from(bestpics).sort(function (a, b) {
      return a.year < b.year ? 1 : -1;
    });

    setPics(sortedPics);
  }

  return (
    <div className="admin__page">
      <h2 className="admin__title">Лучшие фото</h2>
      <div className="form__rowwrap form__buttonrow">
        <FormButton handleClick={handleClose} title="Закрыть" isActive={true} />

        <FormLink
          base={BASE_URL}
          route={`/admin/forms/bestpic/add`}
          name="Добавить"
        />
      </div>
      <div className="form__rowwrap form__buttonrow">
        <FormButton
          handleClick={sortByCountries}
          title="По странам"
          isActive={true}
        />

        <FormButton
          handleClick={sortByYears}
          title="По годам"
          isActive={true}
        />

        <FormLink base={BASE_URL} route={`/admin/pictags`} name="Тэги" />
      </div>

      <div className="admin__table">
        {Array.from(bestpics.reverse()).map((bestpic, i) => {
          return (
            <BestPicsItem
              key={bestpic._id}
              bestpic={bestpic}
              updatelink={`/admin/forms/bestpics/${bestpic._id}`}
            />
          );
        })}
      </div>
    </div>
  );
}

export default AdminBestPics;
