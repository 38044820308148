import React from "react";

import { handleChange } from "../../../utils/functions";

function Input(props) {
  const [isError, setIsError] = React.useState(false);

  function handler(e) {
    handleChange(props.setItem, e, props.errMessage);
  }

  React.useEffect(() => {
    setIsError(!props.item.validity);
  }, [props.item]);

  return (
    <div className="form__inputwrap">
      <h3 className="form__input-name">{props.title}</h3>

      <div className="form__buttonwrap">
        <p
          className={props.select ? "form__addparam" : "hidden-element"}
          onClick={props.handleNew}
        >
          NEW
        </p>

        {props.blocked ? (
          <input
            type={props.type}
            id={props.id}
            name={props.name}
            className={`form__input form__input_${props.size} form__input_blocked`}
            value={props.value}
            disabled={true}
          />
        ) : props.select ? (
          <select
            id={props.id}
            name={props.name}
            className={`form__input form__input_${props.size}`}
            value={props.value}
            onChange={handler}
            required={props.required ? true : false}
            multiple={props.multiple}
            pattern={props.pattern}
          >
            {props.selectionArray.map((item) => {
              return (
                <option id={item.code} key={item.code}>
                  {item.code}
                </option>
              );
            })}
          </select>
        ) : props.body ? (
          <textarea
            type={props.type}
            id={props.id}
            name={props.name}
            className={`form__body`}
            placeholder={props.placeholder}
            value={props.value}
            onChange={handler}
            required={props.required ? true : false}
            pattern={props.pattern}
          />
        ) : (
          <input
            type={props.type}
            id={props.id}
            name={props.name}
            className={`form__input form__input_${props.size}`}
            placeholder={props.placeholder}
            value={props.value}
            onChange={handler}
            required={props.required ? true : false}
            pattern={props.pattern}
          />
        )}
      </div>
      <span className={"form__error"}>
        {isError ? props.errMessage : undefined}
      </span>
    </div>
  );
}

export default Input;
