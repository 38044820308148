export function chooseForm(number, text_arr) {
  let n = Math.abs(number) % 100;
  let n1 = n % 10;
  if (n > 10 && n < 20) {
    return text_arr[2];
  }
  if (n1 > 1 && n1 < 5) {
    return text_arr[1];
  }
  if (n1 === 1) {
    return text_arr[0];
  }
  return text_arr[2];
}

export const tripForms = ["путешествие", "путешествия", "путешествий"];
export const storyForms = ["история", "истории", "историй"];
export const countryForms = ["страна", "страны", "стран"];
export const yearForms = ["год", "года", "лет"];
