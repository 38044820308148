import React from "react";
import { useParams } from "react-router-dom";
import Input from "../../Forms/Input/Input";
import FormButton from "../FormButton/FormButton";
import FormPage from "../FormPage/FormPage";
import HeaderExample from "../HeaderExample/HeaderExample";
import WordsExample from "../WordsExample/WordsExample";

// import {
//   regexLatin,
//   regexRus,
//   regCoverTitleEn,
//   regCoverTitle,
//   regexLink,
// } from "../../../utils/regexFront";
import {
  getContinent,
  updateContinent,
  deleteContinent,
} from "../../../utils/API";
import { checkFormValidity } from "../../../utils/functions";
import { useHistory } from "react-router-dom";

function ContinentForm(props) {
  const { currentCode } = useParams();
  const history = useHistory();

  const [continentCode, setContinentCode] = React.useState({});
  const [continentTitle, setContinentTitle] = React.useState({});
  const [continentTitleEn, setContinentTitleEn] = React.useState({});
  const [coverTitle, setCoverTitle] = React.useState({});
  const [coverTitleEn, setCoverTitleEn] = React.useState({});
  const [continentPic, setContinentPic] = React.useState({});
  // const [continentCountries, setCountries] = React.useState({});
  const [continent, setContinent] = React.useState({});
  const [formValid, setFormValidity] = React.useState(false);

  function handleClose(e) {
    history.goBack();
  }

  React.useEffect(() => {
    getContinent(currentCode)
      .then((res) => {
        res.data === null || res.data === undefined
          ? props.handleNotFound()
          : setContinent(res.data);
        setContinentCode({ value: res.data.code, validity: true });
        setContinentTitle({ value: res.data.title, validity: true });
        setContinentTitleEn({ value: res.data.titleEn, validity: true });
        setCoverTitle({ value: res.data.coverTitle, validity: true });
        setCoverTitleEn({ value: res.data.coverTitleEn, validity: true });
        setContinentPic({ value: res.data.picture, validity: true });
        // setCountries({value:res.data.countries, validity: true});
      })
      .then(() => props.restrictDel())
      .catch((err) => {
        err === 404 ? props.handleNotFound() : console.log(err);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCode]);

  function handleSubmit(e) {
    e.preventDefault();
    updateContinent(currentCode, continent)
      .then(() => {
        history.goBack();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleDelete(e) {
    e.preventDefault();
    props.isDelAllowed
      ? getContinent(currentCode)
          .then((res) =>
            res.data === null || res.data === undefined
              ? props.handleNotFound()
              : deleteContinent(res.data.code)
                  .then(() => {
                    history.goBack();
                  })
                  .then(() => {
                    props.restrictDel();
                  })
          )
          .catch((err) => {
            console.log(err);
          })
      : props.onDeleteClick();
  }

  React.useEffect(() => {
    setContinent({
      code: continentCode.value,
      title: continentTitle.value,
      titleEn: continentTitleEn.value,
      coverTitle: coverTitle.value,
      coverTitleEn: coverTitleEn.value,
      picture: continentPic.value,
      // countries: continentCountries.value
    });
    setFormValidity(
      checkFormValidity(
        continentCode,
        continentTitle,
        continentTitleEn,
        coverTitle,
        coverTitleEn,
        continentPic
        //  continentCountries
      )
    );
  }, [
    continentCode,
    continentTitle,
    continentTitleEn,
    coverTitle,
    coverTitleEn,
    continentPic,
    // continentCountries
  ]);

  return (
    <FormPage name="Континент">
      <p className="form__message">
        Удаление {props.isDelAllowed ? "разрешено" : "запрещено"}
      </p>
      <div className="form__rowwrap form__buttonrow">
        <FormButton
          danger={true}
          handleClick={handleDelete}
          // handleDanger={}
          title="Удалить"
          isActive={true}
        />
        <FormButton handleClick={handleClose} title="Закрыть" isActive={true} />
        <FormButton
          handleClick={handleSubmit}
          title="Обновить"
          isActive={formValid}
        />
      </div>
      <div className="form__rowwrap">
        <Input
          title="Код"
          select={false}
          type="string"
          id="continentCode"
          name="continentCode"
          size="XS"
          value={currentCode}
          blocked="true"
          item={continentCode}
        />
      </div>
      <div className="form__rowwrap">
        <Input
          title="Название по-русски"
          select={false}
          type="text"
          id="continentTitle"
          name="continentTitle"
          size="XL"
          value={continentTitle.value}
          item={continentTitle}
          setItem={setContinentTitle}
          required={true}
          // pattern={regexRus}
          errMessage={"2-50"}
        />
        <Input
          title="Краткое название"
          select={false}
          type="text"
          id="coverTitle"
          name="coverTitle"
          size="L"
          value={coverTitle.value}
          item={coverTitle}
          setItem={setCoverTitle}
          required={true}
          // pattern={regCoverTitle}
          errMessage={"2-25"}
        />
      </div>

      <div className="form__rowwrap">
        <Input
          title="English title"
          select={false}
          type="text"
          id="tripTitleEn"
          name="tripTitleEn"
          size="XL"
          value={continentTitleEn.value}
          item={continentTitleEn}
          setItem={setContinentTitleEn}
          required={false}
          // pattern={regexLatin}
          errMessage={"2-50"}
        />
        <Input
          title="Short"
          select={false}
          type="text"
          id="coverTitleEn"
          name="coverTitleEn"
          size="L"
          value={coverTitleEn.value}
          item={coverTitleEn}
          setItem={setCoverTitleEn}
          required={false}
          // pattern={regCoverTitleEn}
          errMessage={"2-25"}
        />
      </div>
      <div className="form__rowwrap">
        <Input
          title="Титульное фото"
          select={false}
          type="string"
          id="countryPicture"
          name="countryPicture"
          size="XXL"
          value={continentPic.value}
          item={continentPic}
          setItem={setContinentPic}
          required={true}
          // pattern={regexLink}
          errMessage={"ссылка на фото"}
        />
      </div>
      <HeaderExample pic={continentPic.value} words={continentTitle.value} />

      <WordsExample
        items={[
          currentCode,
          continentTitle.value,
          continentTitleEn.value,
          coverTitle.value,
          coverTitleEn.value,
        ]}
      />
    </FormPage>
  );
}

export default ContinentForm;
