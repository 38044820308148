import React from "react";
import Input from "../../Forms/Input/Input";
import FormButton from "../FormButton/FormButton";
import FormPage from "../FormPage/FormPage";
import { useHistory } from "react-router-dom";
import { createPictag } from "../../../utils/API";
import { checkFormValidity } from "../../../utils/functions";

function NewPictagForm(props) {
  const [tagCode, setTagCode] = React.useState({});
  const [title, setTitle] = React.useState({});
  const [titleEn, setTitleEn] = React.useState({});
  const [pictag, setPictag] = React.useState({});
  const [formValid, setFormValidity] = React.useState(false);

  const history = useHistory();

  function handleSubmit(e) {
    e.preventDefault();
    createPictag(pictag)
      .then((res) => {
        history.goBack();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleClose(e) {
    history.goBack();
  }

  React.useEffect(() => {
    setPictag({
      code: tagCode.value,
      title: title.value,
      titleEn: titleEn.value,
    });

    const inputs = [tagCode, title, titleEn];

    setFormValidity(checkFormValidity(inputs));
  }, [tagCode, title, titleEn]);

  return (
    <FormPage name="Новый тэг для фото">
      <div className="form__rowwrap form__buttonrow">
        <FormButton handleClick={handleClose} title="Закрыть" isActive={true} />
        <FormButton
          handleClick={handleSubmit}
          title="Сохранить"
          isActive={formValid}
        />
      </div>

      <div className="form__rowwrap">
        <Input
          title="Код"
          select={false}
          type="string"
          id="tagCode"
          name="tagCode"
          size="M"
          value={tagCode.value}
          item={tagCode}
          setItem={setTagCode}
          required={true}
        />
        <Input
          title="Название"
          select={false}
          type="text"
          id="title"
          name="title"
          size="M"
          value={title.value}
          item={title}
          setItem={setTitle}
          required={true}
        />
        <Input
          title="Name"
          select={false}
          type="text"
          id="titleEn"
          name="titleEn"
          size="M"
          placeholder="20 символов"
          value={titleEn.value}
          item={titleEn}
          setItem={setTitleEn}
          required={true}
        />
      </div>
    </FormPage>
  );
}

export default NewPictagForm;
