import { Link } from "react-router-dom";

import React from "react";
function ContinentLink(props) {
  const [isActive, setIsActive] = React.useState(false);

  function handleMouseOver() {
    setIsActive(true);
  }

  function handleMouseOut() {
    setIsActive(false);
  }

  return (
    <Link
      to={props.link}
      className="continents-link"
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onTouchStart={handleMouseOver}
      onTouchEnd={handleMouseOut}
    >
      <img
        className={
          props.isLarge
            ? "continents-link__picture continents-link__picture_L"
            : "continents-link__picture"
        }
        alt=""
        src={isActive ? props.activeImage : props.inactiveImage}
      />
      <h3 className="continents-link__text">{props.title}</h3>
    </Link>

    // <a
    //   className="continents-link"
    //   href={props.link}
    //   onMouseOver={handleMouseOver}
    //   onMouseOut={handleMouseOut}
    //   onTouchStart={handleMouseOver}
    //   onTouchEnd={handleMouseOut}
    // >
    //   <img
    //     className={
    //       props.isLarge
    //         ? "continents-link__picture continents-link__picture_L"
    //         : "continents-link__picture"
    //     }
    //     alt=""
    //     src={isActive ? props.activeImage : props.inactiveImage}
    //   />
    //   <h3 className="continents-link__text">{props.title}</h3>
    // </a>
  );
}

export default ContinentLink;
