import Card from "../Card/Card";

function Photogrid(props) {
  return (
    <div className="grid-section">
      <h2
        className={
          props.title ? "grid-section__title" : "grid-section__title_hidden"
        }
        id={props.id}
      >
        {props.title}
      </h2>
      <div className="photo-grid">
        {props.cards.map((card, i) => (
          <Card
            card={card}
            key={card.code}
            path={props.path}
            path2={props.path2}
            // это про заполнение карточки, true/false
            date={props.date}
            month={props.month}
            yearMonth={props.yearMonth}
            trips={props.trips}
            stories={props.stories}
          />
        ))}
      </div>
    </div>
  );
}

export default Photogrid;
