import React from "react";
import { PIC_PATH, DB_base } from "../../../utils/constants";

function HeaderExample(props) {
  return (
    <div className="form__rowwrap">
      <div className="form__preview">
        <header
          className="header form__example"
          style={{
            backgroundImage: `url(${
              props.pic ? props.pic.replace(DB_base, PIC_PATH) : ""
            })`,
          }}
        >
          <div className="header__words">
            <h1 className="header__title_large">{props.words}</h1>
          </div>
        </header>
      </div>
    </div>
  );
}

export default HeaderExample;
