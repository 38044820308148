import React from "react";
import { Link } from "react-router-dom";
import { chooseForm, tripForms, storyForms } from "../../utils/wordForms";
import { formatMonth, formatYear, formatDatePPL } from "../../utils/functions";
import { PIC_PATH, DB_base } from "../../utils/constants";

function Card(props) {
  // console.log(props.card);

  const [firstStory, setFirstStory] = React.useState(undefined);
  const [month, setMonth] = React.useState("");
  const [year, setYear] = React.useState("");
  const [fullDate, setFullDate] = React.useState("");
  const stories = props.card.stories;
  const picPath = props.card.coverPic
    ? props.card.coverPic.replace(DB_base, PIC_PATH)
    : "";

  React.useEffect(() => {
    // console.log(stories);
    setFirstStory(
      stories ? stories.find((item) => item.dayNumber === 1) : undefined
    );
  }, [stories]);

  // const stories = props.card.stories;
  // const firstStory = stories.find((item) => item.dayNumber === 1);

  React.useEffect(() => {
    setFullDate(props.card.date ? `${formatDatePPL(props.card.date)}` : "");
  }, [props.card]);

  React.useEffect(() => {
    setMonth(firstStory ? `${formatMonth(firstStory.date)}` : "");
    setYear(firstStory ? `${formatYear(firstStory.date)}` : "");
  }, [firstStory]);

  return (
    <Link
      className="card"
      to={
        props.path2
          ? `${props.path}${props.card.code}${props.path2}`
          : `${props.path}${props.card.code}`
      }
    >
      <img className="card__image" src={picPath} alt="" />
      <div className="card__words">
        <h3 className="card__title">{props.card.coverTitle}</h3>

        {/* В истории дня */}
        <p className={props.date ? "card__text" : "card__text_hidden"}>
          {props.date ? fullDate : undefined}
        </p>

        {/* В по годам */}
        <p className={props.month ? "card__text" : "card__text_hidden"}>
          {props.month ? month : undefined}
        </p>

        {/* В новых */}
        <p className={props.yearMonth ? "card__text" : "card__text_hidden"}>
          {props.yearMonth ? `${month} ${year}` : undefined}
        </p>

        {/* В стране */}
        <p className={props.trips ? "card__text" : "card__text_hidden"}>
          {props.trips
            ? ` ${props.card.trips.length} ${chooseForm(
                props.card.trips.length,
                tripForms
              )} `
            : undefined}{" "}
        </p>

        {/* В по годам, новых и стране */}
        <p className={props.stories ? "card__text" : "card__text_hidden"}>
          {props.stories
            ? `${props.card.stories.length} ${chooseForm(
                props.card.stories.length,
                storyForms
              )}`
            : undefined}
        </p>
      </div>
    </Link>
  );
}
export default Card;

// to={`/countries/${props.card.code}`
