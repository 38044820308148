import React from "react";
import Header from "../Header/Header";
import ContinentsNavigation from "../ContinentsNavigation/ContinentsNavigation";

import { TripsContext } from "../../context/TripsContext";
import { StoriesContext } from "../../context/StoriesContext";
import { CountriesContext } from "../../context/CountriesContext";

import Statistics from "../Statistics/Statistics";
// import homePath from "../../images/IR0107031.jpg";
import Photogrid from "../Photo-grid/Photo-grid";

import {countriesPage} from "../../utils/mainPages";
import { PIC_PATH, DB_base } from "../../utils/constants";



function AllCountries(props) {
  // сделать библиотеку картинок для главных страниц
  // const homePic = homePath;

  const homePic = countriesPage
  ? countriesPage.replace(DB_base, PIC_PATH)
  : "";

  const homeTitle = "Все страны";
  const [countriesNumber, setCountriesNumber] = React.useState("");
  const [tripsNumber, setTripsNumber] = React.useState("");
  const [fullCountries, setFullCountries] = React.useState("");

  const trips = React.useContext(TripsContext);
  const countries = React.useContext(CountriesContext);
  const stories = React.useContext(StoriesContext);

  // const [tripsList, setTripsList] = React.useState("");

  function gatherTripData(country) {
    const countryTrips = trips.filter((trip) =>
      trip.countries.includes(country.code)
    );
    const countryStories = stories.filter(
      (story) => story.country === country.code
    );

    const fullCountry = {
      coverPic: country.coverPic,
      coverTitle: country.coverTitle,
      trips: countryTrips,
      stories: countryStories,
      code: country.code,
      title: country.title,
    };
    return fullCountry;
  }

  React.useEffect(() => {
    const Russia = countries.filter((country) => country.continent === "RU"); 
    setCountriesNumber(countries.length - Russia.length + 1);
  }, [countries]);

  React.useEffect(() => {
    setTripsNumber(trips.length);
  }, [trips]);

  React.useEffect(() => {

    const noRussia = countries.filter((country) => country.continent !== "RU"); 
    const unsortedList = Array.from(noRussia).map((country) =>
      gatherTripData(country)
    );
    const sortedList = unsortedList.sort((a, b) =>
      a.title > b.title ? 1 : -1
    );
    setFullCountries(sortedList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries, trips, stories]);

  return (
    <div className="country">
      <Header headerImage={homePic} title={homeTitle} />
      <ContinentsNavigation path={"../"} />
      <Statistics
        statistics1number={countriesNumber}
        statistics2number={tripsNumber}
        statistics1text="countries"
        statistics2text="trips"
      />
      <Photogrid
        cards={Array.from(fullCountries)}
        date={false}
        month={false}
        yearMonth={false}
        trips={true}
        stories={true}
        path={"../countries/"}
        //  path2={'01'}
      />
    </div>
  );
}

export default AllCountries;
