// export function handleChange(setItem, e) {
//   setItem(e.target.value);
// }

// export function checkVaildity(setValidity, setError, target) {
//    let valid = target.validity.valid;
//    setValidity(valid);
//    setError(!valid);
//  }

export function handleChange(setItem, e, message) {
  // console.log(e.target.validity);

  setItem({
    value: e.target.value,
    validity: e.target.validity.valid,
    errMessage: message,
  });
}

export function checkFormValidity(inputs) {
  if (!inputs) {
    return false;
  } else {
    if (Array.from(inputs).every((input) => input.validity === true)) {
      return true;
    } else {
      return false;
    }
  }
}

export function formatDatePPL(rawDate) {
  const month = rawDate.substr(5, 2);

  function monthName(monthNum) {
    if (monthNum === "01") {
      return "января";
    } else {
      if (monthNum === "02") {
        return "февраля";
      } else {
        if (monthNum === "03") {
          return "марта";
        } else {
          if (monthNum === "04") {
            return "апреля";
          } else {
            if (monthNum === "05") {
              return "мая";
            } else {
              if (monthNum === "06") {
                return "июня";
              } else {
                if (monthNum === "07") {
                  return "июля";
                } else {
                  if (monthNum === "08") {
                    return "августа";
                  } else {
                    if (monthNum === "09") {
                      return "сентября";
                    } else {
                      if (monthNum === "10") {
                        return "октября";
                      } else {
                        if (monthNum === "11") {
                          return "ноября";
                        } else {
                          return "декабря";
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  const year = rawDate.substr(0, 4);
  const day = rawDate.substr(8, 2);

  return `${day} ${monthName(month)} ${year}`;
}

export function formatDate(rawDate) {
  const month = rawDate.substr(5, 2);

  const year = rawDate.substr(0, 4);
  const day = rawDate.substr(8, 2);

  return `${day}-${month}-${year}`;
}

export function formatMonth(rawDate) {
  const monthNum = rawDate.substr(5, 2);

  function monthName(monthNum) {
    if (monthNum === "01") {
      return "Январь";
    } else {
      if (monthNum === "02") {
        return "Февраль";
      } else {
        if (monthNum === "03") {
          return "Март";
        } else {
          if (monthNum === "04") {
            return "Апрель";
          } else {
            if (monthNum === "05") {
              return "Май";
            } else {
              if (monthNum === "06") {
                return "Июнь";
              } else {
                if (monthNum === "07") {
                  return "Июль";
                } else {
                  if (monthNum === "08") {
                    return "Август";
                  } else {
                    if (monthNum === "09") {
                      return "Сентябрь";
                    } else {
                      if (monthNum === "10") {
                        return "Октябрь";
                      } else {
                        if (monthNum === "11") {
                          return "Ноябрь";
                        } else {
                          return "Декабрь";
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  return `${monthName(monthNum)}`;
}

export function formatYear(rawDate) {
  const year = rawDate.substr(0, 4);

  return `${year}`;
}

export function formatDay(rawDate) {
  const day = rawDate.substr(8, 2);

  return `${day}`;
}
