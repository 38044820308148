import SiteNavigation from "../SiteNavigation/SiteNavigation";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="footer">
      <SiteNavigation />
      {/* <a className="copyright" href="/admin">© Ольга Бавыкина (Заботкина) 2003 - 2023 </a> */}
      <Link to="/admin" className="copyright">
        {" "}
        © Ольга Бавыкина (Заботкина) 2003 - 2023
      </Link>
    </footer>
  );
}

export default Footer;
