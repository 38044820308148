import React from "react";
import ReactHtmlParser from "react-html-parser";
import { PIC_PATH, DB_base } from "../../utils/constants";

function StoryBody(props) {
  return (
    <section className="story-content">
      {ReactHtmlParser(
        props.story.body ? props.story.body.replaceAll(DB_base, PIC_PATH) : ""
      )}
    </section>
  );
}
export default StoryBody;
