import React from "react";
import ListItem from "../ListItem/ListItem";
import { getContinents, getCountries } from "../../../utils/API";
import FormLink from "../../Forms/FormLink/FormLink";
import { BASE_URL } from "../../../utils/constants";
import FormButton from "../../Forms/FormButton/FormButton";

// import { continents, trips, countries, stories } from "../../utils/tempDB";

function AdminContinents(props) {
  const [continents, setContinents] = React.useState({});
  const [countries, setCountries] = React.useState({});

  React.useEffect(() => {
    getContinents()
      .then((res) => {
        setContinents(Array.from(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
    getCountries()
      .then((res) => {
        console.log(res.data);
        setCountries(Array.from(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="admin__page">
      <h2 className="admin__title">Континенты и страны</h2>
      <div className="form__rowwrap form__buttonrow">
        <FormButton
          danger={true}
          handleClick={props.onLogout}
          title="Выйти"
          isActive={true}
        />

        <FormLink
          base={BASE_URL}
          route="/admin/forms/continent/add"
          name="Новый континент"
        />

        <FormLink
          base={BASE_URL}
          route="/admin/forms/country/add"
          name="Новая страна"
        />

        <FormLink base={BASE_URL} route="/admin/hashtags" name="Тематики" />

        <FormLink base={BASE_URL} route="/admin/bestpics" name="Лучшие фото" />
      </div>
      <div className="admin__table">
        {Array.from(continents).map((continent, i) => {
          return (
            <ListItem
              key={continent.code}
              title={continent.coverTitle}
              content={Array.from(countries).filter(
                (country) => country.continent === continent.code
              )}
              linkbase={`/admin/countries/`}
              updatelink={`/admin/forms/continents/${continent.code}`}
            />
          );
        })}
      </div>
    </div>
  );
}

export default AdminContinents;
