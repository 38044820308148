import React from "react";
import FormButton from "../FormButton/FormButton";
import FormPage from "../FormPage/FormPage";
import Input from "../Input/Input";
import { checkFormValidity } from "../../../utils/functions";
import FormLink from "../FormLink/FormLink";
import { BASE_URL } from "../../../utils/constants";
import { useHistory } from "react-router-dom";
import { createUser } from "../../../utils/API";

function Register(props) {
  const [login, setLogin] = React.useState({});
  const [password, setPassword] = React.useState({});
  const [user, setUser] = React.useState({});
  const [formValid, setFormValidity] = React.useState(false);
  const history = useHistory();

  function handleSubmit(e) {
    e.preventDefault();
    // console.log("click");
    createUser(user)
      .then((res) => {
        history.push(`/signin`);
      })
      .catch((err) => {
        console.log(err);
        props.onErr(err.message);
      });
  }

  React.useEffect(() => {
    setUser({
      login: login.value,
      password: password.value,
    });

    const inputs = [login, password];

    setFormValidity(checkFormValidity(inputs));
  }, [login, password]);

  return (
    <FormPage name={"Регистрация"}>
      <div className="form__rowwrap form__buttonrow">
        <FormLink base={BASE_URL} route={`/signin`} name="Войти" />

        <FormButton
          handleClick={handleSubmit}
          title="Зарегистрироваться"
          isActive={formValid}
        />
      </div>

      <div className="form__rowwrap">
        <Input
          title="Логин"
          select={false}
          type="string"
          id="login"
          name="login"
          size="L"
          value={login.value}
          item={login}
          setItem={setLogin}
          required={true}
        />
      </div>
      <div className="form__rowwrap">
        <Input
          title="Пароль"
          select={false}
          type="sting"
          id="password"
          name="password"
          size="L"
          value={password.value}
          item={password}
          setItem={setPassword}
          required={true}
        />
      </div>
    </FormPage>
  );
}

export default Register;
