import { Link } from "react-router-dom";

function FormLink(props) {
  return (
    // <a className="form__button" href={`${props.base}${props.route}`}>
    //   {props.name}
    // </a>

    <Link to={`${props.route}`} className="form__button">
      {props.name}
    </Link>
  );
}

export default FormLink;
