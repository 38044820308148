import React from "react";
import { Link } from "react-router-dom";

import FormButton from "../../Forms/FormButton/FormButton";
import FormLink from "../../Forms/FormLink/FormLink";
import { getPictags } from "../../../utils/API";
import { BASE_URL } from "../../../utils/constants";
import { useHistory } from "react-router-dom";

function AdminPictags(props) {
  const history = useHistory();
  const [pictags, setPictags] = React.useState({});

  function handleClose(e) {
    history.goBack();
  }

  React.useEffect(() => {
    getPictags()
      .then((res) => {
        setPictags(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="admin__page">
      <h2 className="admin__title">Тэги для фото</h2>
      <div className="form__rowwrap form__buttonrow">
        <FormLink
          base={BASE_URL}
          route={`/admin/forms/pictag/add`}
          name="Новый тэг"
        />

        <FormButton
          danger={false}
          isActive={true}
          title="Обратно"
          handleClick={handleClose}
        />
      </div>

      <div className="admin__table">
        {Array.from(pictags).map((pictag, i) => {
          return (
            <Link
              to={`/admin/forms/pictags/${pictag.code}`}
              className="item__title"
            >
              {pictag.code}, {pictag.title}, {pictag.titleEn}
            </Link>

            // <a
            //   href={`${BASE_URL}/admin/forms/pictags/${pictag.code}`}
            //   className="item__title"
            // >
            //   {pictag.code}, {pictag.title}, {pictag.titleEn}
            // </a>
          );
        })}
      </div>
    </div>
  );
}

export default AdminPictags;
