import React from "react";
import Header from "../Header/Header";
import ContinentsNavigation from "../ContinentsNavigation/ContinentsNavigation";
import { formatYear, formatMonth } from "../../utils/functions";
import Statistics from "../Statistics/Statistics";
import Gridsection from "../Grid-section/Grid-section";
import { TripsContext } from "../../context/TripsContext";
import { StoriesContext } from "../../context/StoriesContext";
import { CountriesContext } from "../../context/CountriesContext";

import {yearsPage} from "../../utils/mainPages";
import { PIC_PATH, DB_base } from "../../utils/constants";


function AllYears(props) {
  // сделать библиотеку картинок для главных страниц

  const homePic = yearsPage
  ? yearsPage.replace(DB_base, PIC_PATH)
  : "";

  const homeTitle = "По годам";
  const [countriesNumber, setCountriesNumber] = React.useState("");
  const [yearsList, setYearsList] = React.useState("");
  const [fullYears, setFullYears] = React.useState("");

  const trips = React.useContext(TripsContext);
  const countries = React.useContext(CountriesContext);
  const stories = React.useContext(StoriesContext);

  const [tripsList, setTripsList] = React.useState([]);

  function findUniqueYears(trips) {
    let seen = {};
    let result = [];
    let j = 0;

    for (let i = 0; i < trips.length; i++) {
      const item = trips[i].year;
      if (!seen[item]) {
        seen[item] = 1;
        result[j++] = item;
      }
    }
    return result;
  }

  function gatherTripsByYear(year) {

    const yearTrips = tripsList.filter((trip) => trip.year === year);

    const fullYear = {
      title: year,
      cards: yearTrips.sort((a,b)=> a.month < b.month ? 1 : -1),
    };

    return fullYear;
  }

  function gatherTripData(trip) {
    const tripStories = stories.filter((story) => story.trip === trip.code);
    // console.log(new Date(trip.issueDate));
    const fullTrip = {
      code: trip.code,
      coverPic: trip.coverPic,
      coverTitle: trip.coverTitle,
      month: trip.month,
      year: trip.year,
      stories: tripStories,
      issueDate: new Date(trip.issueDate),
    };
    return fullTrip;
  }

  React.useEffect(() => {
    const Russia = countries.filter((country) => country.continent === "RU"); 
    setCountriesNumber(countries.length - Russia.length + 1);
  }, [countries]);

  React.useEffect(() => {
    const unsortedList = Array.from(trips).map((trip) => gatherTripData(trip));


    setTripsList(unsortedList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stories]);

  React.useEffect(() => {
    // eslint-disable-next-line array-callback-return
    tripsList.map((trip) => {
      const firstStory = trip.stories.find((item) => item.dayNumber === 1);
      trip.year = formatYear(firstStory.date);
      trip.month = formatMonth(firstStory.date);
    });
  }, [tripsList]);

  // React.useEffect(() => {
  //   console.log(tripsList);
  // }, [tripsList, stories]);

  React.useEffect(() => {
    setYearsList(findUniqueYears(tripsList));
  }, [tripsList]);

  React.useEffect(() => {
    // console.log(yearsList);

    const sortedYears = Array.from(yearsList).sort(function (a, b) {
      return a < b ? 1 : -1;});

      // console.log(sortedYears);  

    setFullYears(Array.from(sortedYears).map((year) => gatherTripsByYear(year)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yearsList]);

  // React.useEffect(() => {
  //   console.log(fullYears);
  // }, [fullYears]);

  React.useEffect(() => {console.log(fullYears)
  },[fullYears]);

  return (
    <div className="country">
      <Header headerImage={homePic} title={homeTitle} />
      <ContinentsNavigation path={"../"} />
      <Statistics
        statistics1number={yearsList.length}
        statistics2number={countriesNumber}
        statistics1text="years"
        statistics2text="countries"
      />
      <Gridsection
        sections={Array.from(fullYears)}
        date={false}
        month={true}
        yearMonth={false}
        trips={false}
        stories={true}
        path={"../stories/"}
        path2={"01"}
      />
    </div>
  );
}

export default AllYears;
