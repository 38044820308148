// import { getStory } from "../../utils/API"
import React from "react";
import { useParams } from "react-router-dom";
// import { continents, trips, countries, stories } from "../../utils/tempDB";

import { CountriesContext } from "../../context/CountriesContext";
import { TripsContext } from "../../context/TripsContext";
import { StoriesContext } from "../../context/StoriesContext";
import { ContinentsContext } from "../../context/ContinentsContext";

import StoryBody from "../StoryBody/StoryBody";
import StoryHeader from "../StoryHeader/StoryHeader";
import TripNavigation from "../TripNavigation/TripNavigation";
import { formatDate } from "../../utils/functions";

function Story(props) {
  const { storyCode } = useParams();
  const [story, setStory] = React.useState({});
  const [country, setCountry] = React.useState({});
  const [continent, setContinent] = React.useState({});
  const [tripStories, setTripStories] = React.useState({});
  const [yesterday, setYesterday] = React.useState("");
  const [tomorrow, setTomorrow] = React.useState("");
  const [trip, setTrip] = React.useState("");
  const [storyDate, setDate] = React.useState("");

  const trips = React.useContext(TripsContext);
  const countries = React.useContext(CountriesContext);
  const stories = React.useContext(StoriesContext);
  const continents = React.useContext(ContinentsContext);

  React.useEffect(() => {
    if (stories.find((story) => story.code === storyCode)) {
      setStory(stories.find((story) => story.code === storyCode));
      props.handleFind();
    } else {
      props.handleNotFound();
    }
      window.scrollTo(0, 0)
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storyCode, stories]);

  React.useEffect(() => {
    // console.log(story);
    setCountry(countries.find((country) => country.code === story.country));
    setTripStories(stories.filter((item) => item.trip === story.trip));
    setTrip(trips.find((trip) => trip.code === story.trip));
    setDate(story.date ? formatDate(story.date) : "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [story]);

  React.useEffect(() => {
    setContinent(
      country
        ? continents.find((continent) => continent.code === country.continent)
        : ""
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  React.useEffect(() => {
    setYesterday(
      Array.from(tripStories).find(
        (item) => item.dayNumber === story.dayNumber - 1
      )
    );
    setTomorrow(
      Array.from(tripStories).find(
        (item) => item.dayNumber === story.dayNumber + 1
      )
    );
  }, [tripStories, story]);

  return (
    <section className="story">
      <StoryHeader
        country={country}
        continent={continent}
        trip={trip}
        story={story}
        date={storyDate}
      />
      <TripNavigation yesterday={yesterday} tomorrow={tomorrow} />
      <StoryBody story={story} />

      <TripNavigation yesterday={yesterday} tomorrow={tomorrow} />
    </section>
  );
}

export default Story;
