import React from "react";
import { Link } from "react-router-dom";

function ListItem(props) {
  return (
    <div className="item">
      <Link to={`${props.updatelink}`} className="item__title">
        {props.title}
      </Link>

      {/* <a href={`${props.updatelink}`} className="item__title">
        {props.title}
      </a> */}
      <ul className="item__content">
        {Array.from(props.content).map((item) => {
          return (
            // <a
            //   href={`${props.linkbase}${item.code}`}
            //   className="item__content-item"
            // >
            //   {item.title}
            // </a>

            <Link
              to={`${props.linkbase}${item.code}`}
              className="item__content-item"
            >
              {item.title}
            </Link>
          );
        })}
      </ul>
    </div>
  );
}

export default ListItem;
