// import { Link } from "react-router-dom";
import { PIC_PATH, DB_base } from "../../utils/constants";

function VerticalSmallPics(props) {
  return (
    <div className="small-pictures__grid_v">
      {Array.from(props.cards).map((card, i) => {
        const handleClick = () => {
          props.onCardClick(card);
        };

        return (
          <div className="small-pic">
            <img
              className="small-pic__img small-pic__img_v"
              src={card.link ? card.link.replace(DB_base, PIC_PATH) : ""}
              alt=""
              onClick={handleClick}
            />
            {/* <Link
              to={`../countries/${card.country}`}
              className="best-pic__text"
            > */}
              {/* {`${card.title}, ${card.countryTitle}`} */}
            {/* </Link> */}
          </div>
        );
      })}
    </div>
  );
}

export default VerticalSmallPics;
