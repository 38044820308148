import { Link } from "react-router-dom";

function StoryHeader(props) {
  // const date = `${formatDate(props.story.date)} `

  return (
    <header className="story-header">
      <nav className="story-navigation">
        <Link to={"/"} className="story-navigation__link">
          Домой
        </Link>
        <Link to={"/allCountries"} className="story-navigation__link">
          Все страны
        </Link>
        <Link
          to={`/continents/${props.continent ? props.continent.code : ""}`}
          className="story-navigation__link"
        >
          {props.continent ? props.continent.coverTitle : ""}
        </Link>
        <Link
          to={`/countries/${props.country ? props.country.code : ""}`}
          className="story-navigation__link"
        >
          {props.country ? props.country.coverTitle : ""}
        </Link>
      </nav>

      <h2 className="story__title">{props.trip ? props.trip.title : ""}</h2>
      <h3 className="story__date">{props.story ? props.date : ""}</h3>
      <h3 className="story__subtitle">
        {props.story ? props.story.title : ""}
      </h3>
    </header>
  );
}
export default StoryHeader;
