import SiteNavigation from "../SiteNavigation/SiteNavigation";

function Header(props) {
  return (
    <header
      className="header"
      style={{ backgroundImage: `url(${props.headerImage})` }}
    >
      <SiteNavigation />

      <div className="header__words">
        <h1
          className={props.subtitle ? "header__title" : "header__title_large"}
        >
          {props.title}
        </h1>
        <p
          className={
            props.subtitle ? "header__subtitle" : "header__subtitle_hidden"
          }
        >
          {props.subtitle}
        </p>
      </div>
    </header>
  );
}

export default Header;
