import { Link } from "react-router-dom";
import React from "react";
import { PIC_PATH, DB_base } from "../../../utils/constants";


function BestPicsItem(props) {

  const picPath = props.bestpic.link
  ? props.bestpic.link.replace(DB_base, PIC_PATH)
  : "";

  return (
    <Link to={`${props.updatelink}`} className="picitem">
      <img
        src={picPath}
        className="picitem__image"
        alt={props.bestpic.title}
      />

      <div className="picitem__content">
        <p className="picitem__detail">{props.bestpic.title} </p>
        <p className="picitem__detail">{props.bestpic.titleEn} </p>
        <p className="picitem__detail">{props.bestpic.country} </p>
        <p className="picitem__detail">{props.bestpic.trip} </p>
        <p className="picitem__detail">{props.bestpic.hashtags} </p>
        <p className="picitem__detail">{props.bestpic.year} </p>
      </div>
    </Link>

    // <a href={`${props.updatelink}`} className="picitem">
    //   <img
    //     src={props.bestpic.link}
    //     className="picitem__image"
    //     alt={props.bestpic.title}
    //   />

    //   <div className="picitem__content">
    //     <p className="picitem__detail">{props.bestpic.title} </p>
    //     <p className="picitem__detail">{props.bestpic.titleEn} </p>
    //     <p className="picitem__detail">{props.bestpic.country} </p>
    //     <p className="picitem__detail">{props.bestpic.trip} </p>
    //     <p className="picitem__detail">{props.bestpic.hashtags} </p>
    //     <p className="picitem__detail">{props.bestpic.year} </p>
    //   </div>
    // </a>
  );
}

export default BestPicsItem;
