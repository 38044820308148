function FormPage(props) {
  return (
    <div className="form-page">
      <h2 className="form__title">{props.name}</h2>
      <form className="form" noValidate>
        {props.children}
      </form>
    </div>
  );
}

export default FormPage;
