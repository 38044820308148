    
    
    import React from "react";
    // import { bestPictures } from "../../utils/tempDB";
    import SiteNavigation from "../SiteNavigation/SiteNavigation";
    import { BestPicsContext } from "../../context/BestPicsContext";
    import VerticalSmallPics from "../VerticalSmallPics/VerticalSmallPics";
    import HorizontalSmallPics from "../HorizontalSmallPics/HorizontalSmallPics";
    import {CountriesContext} from "../../context/CountriesContext"
    // import HorizontalPicsGrid from "../HorizontalPicsGrid/HorizontalPicsGrid";
    // import { WIDESCREEN, SMALLSCREEN } from "../../utils/constants";
    
    
    function Contacts(props) {
      const bestPictures = React.useContext(BestPicsContext);
      const [mePictures, setMePictures] = React.useState("");
      const [vPictures, setVPictures] = React.useState("");
      const [hPictures, setHPictures] = React.useState("");
      const [fstHSlice, setFstHSlice] = React.useState("");
      const [scndHSlice, setScndHSlice] = React.useState("");
      const [fstVSlice, setFstVSlice] = React.useState("");
      const [scndVSlice, setScndVSlice] = React.useState("");
      const countries = React.useContext(CountriesContext);
      // eslint-disable-next-line no-unused-vars
      const [hNumber, setHNumber] = React.useState(10);
      // eslint-disable-next-line no-unused-vars
      const [vNumber, setVNumber] = React.useState(7);

      // const [hPictures, setHPictures] = React.useState("");
      const shuffle = (arr) =>
      arr.sort(function () {
        return Math.random() - 0.5;
      });

      
      React.useEffect(() => {
        if (countries.length > 0 )
        {setMePictures(bestPictures.filter((item) => item.hashtags.includes('trueme')))};
      }, [bestPictures, countries]);

      React.useEffect(() => {
        setVPictures(shuffle(Array.from(mePictures).filter((item) => item.orientation === 'V')));
        setHPictures(shuffle(Array.from(mePictures).filter((item) => item.orientation === 'H')))
      }, [mePictures]);

      React.useEffect(() => {
        setFstHSlice(hPictures.slice(0, hNumber));
        const turns = Math.floor((hPictures.length-1)/hNumber)
        setScndHSlice(hPictures.slice(hNumber,turns*hNumber));
      }, [hNumber,hPictures]);

      React.useEffect(() => {
        setFstVSlice(vPictures.slice(0, vNumber));
        const turns = Math.floor((vPictures.length-1)/vNumber)
        setScndVSlice(vPictures.slice(vNumber,turns*vNumber));
      }, [vNumber,vPictures]);

      // React.useEffect(() => {
      //   console.log(vPictures)
      // }, [vPictures]);

  
        return (
            <>
              <SiteNavigation />
              <VerticalSmallPics
              cards={fstVSlice}
              key={1}
              onCardClick={props.onCardClick}
              />

              <h2 className="best-pictures__title">Про меня</h2> 
              <HorizontalSmallPics
              cards={fstHSlice}
              key={2}
              onCardClick={props.onCardClick}
              />


              <div className="contacts-content">
              <p className="contacts-content__text">Меня зовут Оля</p>
              <p className="contacts-content__text">Я люблю своих многочисленных детей, простор и зеркальный фотоаппарат Canon</p>
              <p className="contacts-content__text">Я не профессиональный фотограф, просто без камеры мало вижу и совсем ничего не запоминаю. А запомнить хочется</p>
              <p className="contacts-content__text">Фотографии без спроса брать нельзя</p>
              <p className="contacts-content__text">А еще я сама сделала весь этот сайт</p>
              <p className="contacts-content__text">Мне можно написать на olga@myfriendthewind.ru</p>
              </div>

              <HorizontalSmallPics
              cards={scndHSlice}
              key={3}
              onCardClick={props.onCardClick}
              />
              <VerticalSmallPics
              cards={scndVSlice}
              key={4}
              onCardClick={props.onCardClick}
              />

    </>
  );
}
export default Contacts;