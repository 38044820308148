import React from "react";
import Input from "../../Forms/Input/Input";
import FormButton from "../FormButton/FormButton";
import FormPage from "../FormPage/FormPage";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { getPictag, updatePictag, deletePictag } from "../../../utils/API";
import { checkFormValidity } from "../../../utils/functions";

function NewPictagForm(props) {
  const { currentCode } = useParams();
  const [tagCode, setTagCode] = React.useState({});
  const [title, setTitle] = React.useState({});
  const [titleEn, setTitleEn] = React.useState({});
  const [pictag, setPictag] = React.useState({});
  const [formValid, setFormValidity] = React.useState(false);

  const history = useHistory();

  function handleSubmit(e) {
    e.preventDefault();
    updatePictag(currentCode, pictag)
      .then((res) => {
        history.goBack();
      })
      .catch((err) => {
        console.log(err);
        props.onErr(err.message);
      });
  }

  function handleClose(e) {
    history.goBack();
  }

  function handleDelete(e) {
    e.preventDefault();
    props.isDelAllowed
      ? getPictag(currentCode)
          .then((res) =>
            res.data === null || res.data === undefined
              ? props.handleNotFound()
              : deletePictag(res.data.code).then(() => {
                  history.goBack();
                })
          )
          .then(() => {
            props.restrictDel();
          })

          .catch((err) => {
            console.log(err);
          })
      : props.onDeleteClick();
  }

  React.useEffect(() => {
    getPictag(currentCode)
      .then((res) => {
        res.data === null || res.data === undefined
          ? props.handleNotFound()
          : setPictag(res.data);
        setTitle({ value: res.data.title, validity: true });
        setTitleEn({ value: res.data.titleEn, validity: true });
        setTagCode({ value: res.data.code, validity: true });
      })
      .then(() => {
        props.restrictDel();
      })
      .catch((err) => {
        err === 404 ? props.handleNotFound() : console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCode]);

  React.useEffect(() => {
    setPictag({
      code: tagCode.value,
      title: title.value,
      titleEn: titleEn.value,
    });

    const inputs = [tagCode, title, titleEn];

    setFormValidity(checkFormValidity(inputs));
  }, [tagCode, title, titleEn]);

  return (
    <FormPage name={title.value}>
      <p className="form__message">
        Удаление {props.isDelAllowed ? "разрешено" : "запрещено"}
      </p>
      <div className="form__rowwrap form__buttonrow">
        <FormButton
          danger={true}
          handleClick={handleDelete}
          title="Удалить"
          isActive={true}
        />
        <FormButton handleClick={handleClose} title="Закрыть" isActive={true} />
        <FormButton
          handleClick={handleSubmit}
          title="Обновить"
          isActive={formValid}
        />
      </div>

      <div className="form__rowwrap">
        <Input
          title="Код"
          select={false}
          type="string"
          id="tagCode"
          name="tagCode"
          size="M"
          value={tagCode.value}
          item={tagCode}
          setItem={setTagCode}
          required={true}
        />
        <Input
          title="Название"
          select={false}
          type="text"
          id="title"
          name="title"
          size="M"
          value={title.value}
          item={title}
          setItem={setTitle}
          required={true}
        />
        <Input
          title="Name"
          select={false}
          type="text"
          id="titleEn"
          name="titleEn"
          size="M"
          placeholder="20 символов"
          value={titleEn.value}
          item={titleEn}
          setItem={setTitleEn}
          required={true}
        />
      </div>
    </FormPage>
  );
}

export default NewPictagForm;
