import React from "react";
import Input from "../../Forms/Input/Input";
import FormButton from "../FormButton/FormButton";
import FormPage from "../FormPage/FormPage";
import HeaderExample from "../HeaderExample/HeaderExample";
import WordsExample from "../WordsExample/WordsExample";

// import {
//   regexLatin,
//   regContinentCode,
//   regexRus,
//   regCoverTitleEn,
//   regCoverTitle,
//   regexLink,
// } from "../../../utils/regexFront";
import { createContinent } from "../../../utils/API";
import { checkFormValidity } from "../../../utils/functions";
import { useHistory } from "react-router-dom";

function NewContinentForm(props) {
  const [continentCode, setContinentCode] = React.useState({});
  const [continentTitle, setContinentTitle] = React.useState({});
  const [continentTitleEn, setContinentTitleEn] = React.useState({});
  const [coverTitle, setCoverTitle] = React.useState({});
  const [coverTitleEn, setCoverTitleEn] = React.useState({});
  const [continentPic, setContinentPic] = React.useState({});
  // const [continentCountries, setCountries] = React.useState({});
  const [continent, setContinent] = React.useState({});
  const [formValid, setFormValidity] = React.useState(false);

  const history = useHistory();

  function handleSubmit(e) {
    e.preventDefault();
    createContinent(continent)
      .then((res) => {
        history.goBack();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleClose(e) {
    history.goBack();
  }

  React.useEffect(() => {
    setContinent({
      code: continentCode.value,
      title: continentTitle.value,
      titleEn: continentTitleEn.value,
      coverTitle: coverTitle.value,
      coverTitleEn: coverTitleEn.value,
      picture: continentPic.value,
      // countries: continentCountries.value
    });

    const inputs = [
      continentCode,
      continentTitle,
      continentTitleEn,
      coverTitle,
      coverTitleEn,
      continentPic,
      // continentCountries
    ];

    setFormValidity(checkFormValidity(inputs));
  }, [
    continentCode,
    continentTitle,
    continentTitleEn,
    coverTitle,
    coverTitleEn,
    continentPic,
  ]);

  return (
    <FormPage name="Новый континент">
      <div className="form__rowwrap form__buttonrow">
        <FormButton handleClick={handleClose} title="Закрыть" isActive={true} />
        <FormButton
          handleClick={handleSubmit}
          title="Сохранить"
          isActive={formValid}
        />
      </div>
      <div className="form__rowwrap">
        <Input
          title="Код"
          select={false}
          type="string"
          id="continentCode"
          name="continentCode"
          size="XS"
          value={continentCode.value}
          item={continentCode}
          errMessage={"2 latin caps"}
          setItem={setContinentCode}
          required={true}
          // pattern={regContinentCode}
        />
      </div>
      <div className="form__rowwrap">
        <Input
          title="Название по-русски"
          select={false}
          type="text"
          id="continentTitle"
          name="continentTitle"
          size="XL"
          value={continentTitle.value}
          item={continentTitle}
          setItem={setContinentTitle}
          required={true}
          // pattern={regexRus}
          errMessage={"2-50"}
        />
        <Input
          title="Краткое название"
          select={false}
          type="text"
          id="coverTitle"
          name="coverTitle"
          size="L"
          value={coverTitle.value}
          item={coverTitle}
          setItem={setCoverTitle}
          required={true}
          // pattern={regCoverTitle}
          errMessage={"2-25"}
        />
      </div>

      <div className="form__rowwrap">
        <Input
          title="English title"
          select={false}
          type="text"
          id="tripTitleEn"
          name="tripTitleEn"
          size="XL"
          value={continentTitleEn.value}
          item={continentTitleEn}
          setItem={setContinentTitleEn}
          required={false}
          // pattern={regexLatin}
          errMessage={"2-50"}
        />
        <Input
          title="Short"
          select={false}
          type="text"
          id="coverTitleEn"
          name="coverTitleEn"
          size="L"
          value={coverTitleEn.value}
          item={coverTitleEn}
          setItem={setCoverTitleEn}
          required={false}
          // pattern={regCoverTitleEn}
          errMessage={"2-25"}
        />
      </div>
      <div className="form__rowwrap"></div>

      <div className="form__rowwrap">
        <Input
          title="Титульное фото"
          select={false}
          type="string"
          id="countryPicture"
          name="countryPicture"
          size="XXL"
          value={continentPic.value}
          item={continentPic}
          setItem={setContinentPic}
          required={true}
          // pattern={regexLink}
          errMessage={"ссылка на фото"}
        />
      </div>

      <HeaderExample pic={continentPic.value} words={continentTitle.value} />

      <WordsExample
        items={[
          continentCode.value,
          continentTitle.value,
          continentTitleEn.value,
          coverTitle.value,
          coverTitleEn.value,
        ]}
      />
    </FormPage>
  );
}

export default NewContinentForm;
