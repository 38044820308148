import React from "react";
import Input from "../../Forms/Input/Input";
import FormButton from "../FormButton/FormButton";
import FormPage from "../FormPage/FormPage";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../../../utils/constants";
import CardExample from "../CardExample/CardExample";
import WordsExample from "../WordsExample/WordsExample";
import FormLink from "../../Forms/FormLink/FormLink";
import ReactHtmlParser from "react-html-parser";

import {
  getStory,
  updateStory,
  deleteStory,
  getTrips,
  getCountries,
} from "../../../utils/API";
import { checkFormValidity } from "../../../utils/functions";
import { useHistory } from "react-router-dom";

import { PIC_PATH, DB_base } from "../../../utils/constants";
// import { regexRus, regCoverTitle, regexLink } from "../../../utils/regexFront";

function StoryForm(props) {
  const { currentCode } = useParams();
  const history = useHistory();

  const [storyCountry, setStoryCountry] = React.useState({});
  const [storyTrip, setTrip] = React.useState({});
  const [dayNumber, setDayNumber] = React.useState({});
  const [storyDate, setStoryDate] = React.useState({});
  const [storyTitle, setStoryTitle] = React.useState({});
  const [coverTitle, setCoverTitle] = React.useState({});
  const [picsPath, setPicsPath] = React.useState({});
  const [coverPic, setCoverPic] = React.useState({});
  const [storyBody, setStoryBody] = React.useState([]);
  const [story, setStory] = React.useState([]);
  const [formValid, setFormValidity] = React.useState(false);
  const [countries, setCountries] = React.useState([]);
  const [trips, setTrips] = React.useState([]);

  function handleSubmit(e) {
    e.preventDefault();
    updateStory(currentCode, story)
      .then((res) => {
        history.goBack();
      })
      .catch((err) => {
        console.log(err);
        props.onErr(err.message);
      });
  }

  function handleClose(e) {
    history.goBack();
  }

  function handleDelete(e) {
    e.preventDefault();
    props.isDelAllowed
      ? getStory(currentCode)
          .then((res) =>
            res.data === null || res.data === undefined
              ? props.handleNotFound()
              : deleteStory(res.data.code).then(() => {
                  history.goBack();
                })
          )
          .then(() => {
            props.restrictDel();
          })

          .catch((err) => {
            console.log(err);
          })
      : props.onDeleteClick();
  }

  function makeNewCountry() {
    history.push("/admin/forms/country/add");
  }

  function makeNewTrip() {
    history.push("/admin/forms/trip/add");
  }

  React.useEffect(() => {
    getCountries()
      .then((res) => {
        console.log(res.data);
        setCountries(Array.from(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
    getTrips()
      .then((res) => {
        console.log(res.data);
        setTrips(Array.from(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  React.useEffect(() => {
    console.log(currentCode);
    getStory(currentCode)
      .then((res) => {
        res.data === null || res.data === undefined
          ? props.handleNotFound()
          : setStory(res.data);
        setStoryCountry({ value: res.data.country, validity: true });
        setStoryDate({ value: res.data.date, validity: true });
        setTrip({ value: res.data.trip, validity: true });
        setDayNumber({ value: res.data.dayNumber, validity: true });
        setStoryTitle({ value: res.data.title, validity: true });
        setCoverTitle({ value: res.data.coverTitle, validity: true });
        setPicsPath({ value: res.data.picsPath, validity: true });
        setCoverPic({ value: res.data.coverPic, validity: true });
        setStoryBody({ value: res.data.body, validity: true });
      })
      .then(() => {
        props.restrictDel();
      })
      .catch((err) => {
        err === 404 ? props.handleNotFound() : console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCode]);

  React.useEffect(() => {
    setStory({
      country: storyCountry.value,
      trip: storyTrip.value,
      dayNumber: dayNumber.value,
      date: storyDate.value,
      title: storyTitle.value,
      // titleEn: continentTitleEn.value,
      coverTitle: coverTitle.value,
      // coverTitleEn: coverTitleEn.value,
      picsPath: picsPath.value,
      coverPic: coverPic.value,
      body: storyBody.value,
    });

    const inputs = [
      storyCountry,
      storyTrip,
      dayNumber,
      storyDate,
      storyTitle,
      coverTitle,
      picsPath,
      coverPic,
      storyBody,
    ];

    setFormValidity(checkFormValidity(inputs));
  }, [
    storyCountry,
    storyTrip,
    dayNumber,
    storyDate,
    storyTitle,
    coverTitle,
    picsPath,
    coverPic,
    storyBody,
  ]);

  React.useEffect(() => {
    console.log(story);
  }, [story]);

  return (
    <FormPage name={`${story.title}, день номер ${story.dayNumber}`}>
      <p className="form__message">
        Удаление {props.isDelAllowed ? "разрешено" : "запрещено"}
      </p>
      <div className="form__rowwrap form__buttonrow">
        <FormButton
          danger={true}
          handleClick={handleDelete}
          title="Удалить"
          isActive={true}
        />
        <FormButton handleClick={handleClose} title="Закрыть" isActive={true} />
        <FormButton
          handleClick={handleSubmit}
          title="Обновить"
          isActive={formValid}
        />
        <FormLink
          base={BASE_URL}
          route={`/admin/forms/stories_eng/${currentCode}`}
          name="Английский"
        />
      </div>

      <div className="form__rowwrap">
        <Input
          title="Страна"
          select={true}
          selectionArray={countries}
          type="text"
          id="country"
          name="country"
          size="M"
          placeholder=""
          value={storyCountry.value}
          item={storyCountry}
          setItem={setStoryCountry}
          required={true}
          handleNew={makeNewCountry}
        />

        <Input
          title="Код поездки"
          select={true}
          selectionArray={trips}
          type="string"
          id="trip"
          name="trip"
          size="S"
          placeholder=""
          value={storyTrip.value}
          item={storyTrip}
          setItem={setTrip}
          required={true}
          handleNew={makeNewTrip}
        />

        <Input
          title="День #"
          select={false}
          type="number"
          id="dayNumber"
          name="dayNumber"
          size="XS"
          placeholder="Номер"
          value={dayNumber.value}
          item={dayNumber}
          setItem={setDayNumber}
          required={true}
        />

        <Input
          title="Дата"
          select={false}
          type="date"
          id="storyDate"
          name="storyDate"
          size="M"
          value={storyDate.value}
          item={storyDate}
          setItem={setStoryDate}
          required={true}
        />
      </div>
      <div className="form__rowwrap">
        <Input
          title="Название дня"
          select={false}
          type="text"
          id="storyTitle"
          name="storyTitle"
          size="XL"
          placeholder="50 символов"
          value={storyTitle.value}
          item={storyTitle}
          setItem={setStoryTitle}
          required={true}
          // pattern={regexRus}
        />

        <Input
          title="Краткое название"
          select={false}
          type="text"
          id="coverTitle"
          name="coverTitle"
          size="M"
          placeholder="20 символов"
          value={coverTitle.value}
          item={coverTitle}
          setItem={setCoverTitle}
          required={true}
          // pattern={regCoverTitle}
        />
      </div>
      <div className="form__rowwrap">
        {/* <Input
          title="Путь к файлам с фото"
          select={false}
          type="url"
          id="picsPath"
          name="picsPath"
          size="XL"
          placeholder="url"
          value={picsPath.value}
          item={picsPath}
          setItem={setPicsPath}
          required={false}
          pattern={regexLink}
        /> */}

        <Input
          title="Титульное фото"
          select={false}
          type="string"
          id="coverPic"
          name="coverPic"
          size="XXL"
          placeholder="имя файла"
          value={coverPic.value}
          item={coverPic}
          setItem={setCoverPic}
          required={false}
        />
      </div>

      <div className="form__rowwrap">
        <Input
          body={true}
          title="Рассказ"
          select={false}
          type="string"
          id="storyBody"
          name="storyBody"
          size="M"
          placeholder="пиши сюда"
          value={storyBody.value}
          item={storyBody}
          setItem={setStoryBody}
          required={false}
        />
      </div>

      <CardExample pic={`${coverPic.value}`} words={coverTitle.value} />

      <WordsExample
        items={[
          storyCountry.value,
          storyTrip.value,
          dayNumber.value,
          storyDate.value,
          coverTitle.value,
          picsPath.value,
          coverPic.value,
          storyTitle.value,
        ]}
      />

      <section className="story">
        {ReactHtmlParser(
          storyBody.value ? storyBody.value.replaceAll(DB_base, PIC_PATH) : ""
        )}
      </section>
    </FormPage>
  );
}

export default StoryForm;
