function More(props) {
  return (
    <div className="more">
      {props.isActive
      ?
      <button
      type="submit"
      className="more__button"
      onClick={props.handleClick}
      >
        Еще
      </button>
      : undefined}
    </div>
  );
}

export default More;
