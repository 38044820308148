import Photogrid from "../Photo-grid/Photo-grid";

function Gridsection(props) {
  return (
    <section className="grid-sections">
      {props.sections.map((section, i) => (
        <Photogrid
          title={section.title}
          cards={Array.from(section.cards)}
          key={section.code}
          id={section.code}
          path={props.path}
          path2={props.path2}
          // это про заполнение карточки, true/false
          date={props.date}
          month={props.month}
          yearMonth={props.yearMonth}
          trips={props.trips}
          stories={props.stories}
        />
      ))}
    </section>
  );
}

export default Gridsection;
