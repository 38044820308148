import { Link } from "react-router-dom";
import { PIC_PATH, DB_base } from "../../utils/constants";

function HorizontalPicsGrid(props) {
  return (
    <div className="best-pictures__grid_h">
      {Array.from(props.cards).map((card, i) => {
        const handleClick = () => {
          props.onCardClick(card);
        };

        return (
          <div className="best-pic">
            <img
              className="best-pic__img best-pic__img_h"
              src={card.link ? card.link.replace(DB_base, PIC_PATH) : ""}
              alt=""
              onClick={handleClick}
            />
            <Link
              to={`../countries/${card.country}`}
              className="best-pic__text"
            >
              {`${card.title}, ${card.countryTitle}`}
            </Link>
          </div>
        );
      })}
    </div>
  );
}

export default HorizontalPicsGrid;
