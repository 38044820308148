import React from "react";
import Input from "../../Forms/Input/Input";
import FormButton from "../FormButton/FormButton";
import FormPage from "../FormPage/FormPage";
import HeaderExample from "../HeaderExample/HeaderExample";
import CardExample from "../CardExample/CardExample";
import WordsExample from "../WordsExample/WordsExample";

import // regexLatin,
// regCountrytCode,
// regexRus,
// regCoverTitleEn,
// regCoverTitle,
// regexLink,
"../../../utils/regexFront";
import { createCountry, getContinents } from "../../../utils/API";
import { checkFormValidity } from "../../../utils/functions";
import { useHistory } from "react-router-dom";

function NewCountryForm(props) {
  const history = useHistory();

  const [countryCode, setCountryCode] = React.useState({});
  const [countryContinent, setContinent] = React.useState({});
  const [countryTitle, setCountryTitle] = React.useState({});
  const [countryTitleEn, setCountryTitleEn] = React.useState({});
  const [coverTitle, setCoverTitle] = React.useState({});
  const [coverTitleEn, setCoverTitleEn] = React.useState({});
  const [countryPic, setCountryPic] = React.useState({});
  const [coverPic, setCoverPic] = React.useState({});
  const [country, setCountry] = React.useState({});
  const [formValid, setFormValidity] = React.useState(false);
  const [continents, setContinents] = React.useState([]);

  function handleSubmit(e) {
    e.preventDefault();
    createCountry(country)
      .then((res) => {
        history.push(`/admin/countries/${countryCode.value}`);
      })
      .catch((err) => {
        console.log(err);
        props.onErr(err.message);
      });
  }

  function handleClose(e) {
    history.goBack();
  }

  function makeNewContinent() {
    history.push("/admin/forms/continent/add");
  }

  React.useEffect(() => {
    getContinents()
      .then((res) => {
        setContinents(Array.from(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // React.useEffect(() => {
  //   console.log(continents)
  // }, [continents])

  React.useEffect(() => {
    setCountry({
      code: countryCode.value,
      title: countryTitle.value,
      titleEn: countryTitleEn.value,
      coverTitle: coverTitle.value,
      coverTitleEn: coverTitleEn.value,
      picture: countryPic.value,
      coverPic: coverPic.value,
      continent: countryContinent.value,
    });

    const inputs = [
      countryCode,
      countryTitle,
      countryTitleEn,
      coverTitle,
      coverTitleEn,
      countryPic,
      coverPic,
      countryContinent,
    ];

    setFormValidity(checkFormValidity(inputs));
  }, [
    countryCode,
    countryTitle,
    countryTitleEn,
    coverTitle,
    coverTitleEn,
    countryPic,
    coverPic,
    countryContinent,
  ]);

  return (
    <FormPage name="Новая страна">
      <div className="form__rowwrap form__buttonrow">
        <FormButton handleClick={handleClose} title="Закрыть" isActive={true} />
        <FormButton
          handleClick={handleSubmit}
          title="Сохранить"
          isActive={formValid}
        />
      </div>

      <div className="form__rowwrap">
        <Input
          title="Код"
          select={false}
          type="string"
          id="countryCode"
          name="countryCode"
          size="S"
          value={countryCode.value}
          item={countryCode}
          errMessage={"2-3 latin caps"}
          setItem={setCountryCode}
          required={true}
          // pattern={regCountrytCode}
        />

        <Input
          title="Континент"
          select={true}
          selectionArray={continents}
          type="text"
          id="country"
          name="country"
          size="M"
          placeholder=""
          value={countryContinent.value}
          item={countryContinent}
          setItem={setContinent}
          required={true}
          handleNew={makeNewContinent}
        />
      </div>
      <div className="form__rowwrap">
        <Input
          title="Название по-русски"
          select={false}
          type="text"
          id="countryTitle"
          name="counryTitle"
          size="XL"
          placeholder="50 символов"
          value={countryTitle.value}
          item={countryTitle}
          setItem={setCountryTitle}
          required={true}
          // pattern={regexRus}
          errMessage={"2-50"}
        />
        <Input
          title="Краткое название"
          select={false}
          type="text"
          id="coverTitle"
          name="coverTitle"
          size="L"
          placeholder="20 символов"
          value={coverTitle.value}
          item={coverTitle}
          setItem={setCoverTitle}
          required={true}
          // pattern={regCoverTitle}
          errMessage={"2-25"}
        />
      </div>

      <div className="form__rowwrap">
        <Input
          title="English title"
          select={false}
          type="text"
          id="tripTitleEn"
          name="tripTitleEn"
          size="XL"
          value={countryTitleEn.value}
          item={countryTitleEn}
          setItem={setCountryTitleEn}
          required={false}
          // pattern={regexLatin}
          errMessage={"2-50"}
        />
        <Input
          title="Short"
          select={false}
          type="text"
          id="coverTitleEn"
          name="coverTitleEn"
          size="L"
          value={coverTitleEn.value}
          item={coverTitleEn}
          setItem={setCoverTitleEn}
          required={false}
          // pattern={regCoverTitleEn}
          errMessage={"2-25"}
        />
      </div>

      <div className="form__rowwrap ">
        <Input
          title="Титульное фото"
          select={false}
          type="string"
          id="countryPicture"
          name="countryPicture"
          size="XXL"
          value={countryPic.value}
          item={countryPic}
          setItem={setCountryPic}
          required={false}
          // pattern={regexLink}
          errMessage={"ссылка на фото"}
        />
      </div>
      <div className="form__rowwrap ">
        <Input
          title="Заглавное фото"
          select={false}
          type="string"
          id="coverPic"
          name="coverPic"
          size="XXL"
          value={coverPic.value}
          item={coverPic}
          setItem={setCoverPic}
          required={false}
          // pattern={regexLink}
          errMessage={"ссылка на фото"}
        />
      </div>

      <HeaderExample pic={countryPic.value} words={countryTitle.value} />

      <CardExample pic={coverPic.value} words={coverTitle.value} />

      <WordsExample
        items={[
          countryCode.value,
          countryContinent.value,
          countryTitle.value,
          countryTitleEn.value,
          coverTitle.value,
          coverTitleEn.value,
        ]}
      />
    </FormPage>
  );
}

export default NewCountryForm;
