import React from "react";
import { PIC_PATH, DB_base } from "../../../utils/constants";

function BestPicExample(props) {
  return (
    <div className="form__rowwrap">
      <div className="best-pic">
        <img
          className="best-pic__img best-pic__img_h"
          src={props.link ? props.link.replace(DB_base, PIC_PATH) : ""}
          alt=""
        />
      </div>
    </div>
  );
}

export default BestPicExample;
