import { Link, useHistory } from "react-router-dom";

function Error(props) {
  const history = useHistory();

  const onClose = () => {
    props.onClose();
    history.goBack();
  };

  return (
    <div
      className={props.isError ? "error " : "error error_off"}
      onClick={props.onClick}
    >
      <p className="error__message">Такой страницы нет</p>
      <p className="error__link" onClick={onClose}>
        Обратно
      </p>
      <Link className="error__link" to="../">
        Домой
      </Link>
      <Link className="error__link" to="../allCountries">
        Все страны
      </Link>
    </div>
  );
}

export default Error;
