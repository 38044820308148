import React from "react";

function WordsExample(props) {
  return (
    <div className="form__rowwrap">
      <div className="form__values-check">
        {Array.from(props.items).map((item, i) => {
          return <p className="story-content__text">{item}</p>;
        })}
      </div>
    </div>
  );
}

export default WordsExample;
