import { BASE_URL_API } from "../utils/constants";

function checkAnswer(res) {
  if (res.ok) {
    return res.json();
  }
  return Promise.reject(res.status);
}
// auth
export const createUser = (user) => {
  return fetch(`${BASE_URL_API}/signup`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    credentials: "include",

    body: JSON.stringify({
      login: user.login,
      password: user.password,
    }),
  })
    .then((res) => {
      return checkAnswer(res);
    })
    .then((data) => {
      return data;
    });
};

export const authorize = (user) => {
  return fetch(`${BASE_URL_API}/signin`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify({
      password: user.password,
      login: user.login,
    }),
  })
    .then((res) => {
      return checkAnswer(res);
    })
    .then((data) => {
      return data;
    });
};

export const updateUser = (userLogin, user) => {
  return fetch(`${BASE_URL_API}/users/${userLogin}`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    credentials: "include",

    body: JSON.stringify({
      login: user.login,
      password: user.password,
    }),
  })
    .then((res) => {
      return checkAnswer(res);
    })
    .then((data) => {
      return data;
    });
};

export const getUser = (userLogin) => {
  return fetch(`${BASE_URL_API}/users/${userLogin}`, {
    method: "GET",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((res) => {
    return checkAnswer(res);
  });
};

// stories
export const createStory = (story) => {
  return fetch(`${BASE_URL_API}/stories`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    credentials: "include",

    body: JSON.stringify({
      country: story.country,
      trip: story.trip,
      dayNumber: story.dayNumber,
      date: story.date,
      title: story.title,
      titleEn: story.titleEn,
      coverTitle: story.coverTitle,
      coverTitleEn: story.coverTitleEn,
      picsPath: story.picsPath,
      coverPic: story.coverPic,
      body: story.body,
      bodyEn: story.bodyEn,
    }),
  })
    .then((res) => {
      return checkAnswer(res);
    })
    .then((data) => {
      return data;
    });
};

export const getStory = (storyCode) => {
  return fetch(`${BASE_URL_API}/stories/${storyCode}`, {
    method: "GET",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((res) => {
    return checkAnswer(res);
  });
};

export const getStories = (param) => {
  return fetch(`${BASE_URL_API}/stories`, {
    method: "GET",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(param),
  }).then((res) => {
    return checkAnswer(res);
  });
};

export const updateStory = (storyCode, story) => {
  return fetch(`${BASE_URL_API}/stories/${storyCode}`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify({
      trip: story.trip,
      dayNumber: story.dayNumber,
      date: story.date,
      title: story.title,
      titleEn: story.titleEn,
      coverTitle: story.coverTitle,
      coverTitleEn: story.coverTitleEn,
      picsPath: story.picsPath,
      coverPic: story.coverPic,
      body: story.body,
      bodyEn: story.bodyEn,
      country: story.country,
    }),
  }).then((res) => {
    return checkAnswer(res);
  });
};

export const deleteStory = (storyCode) => {
  return fetch(`${BASE_URL_API}/stories/${storyCode}`, {
    method: "DELETE",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((res) => {
    return checkAnswer(res);
  });
};

// trips

export const createTrip = (trip) => {
  return fetch(`${BASE_URL_API}/trips`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    credentials: "include",

    body: JSON.stringify({
      code: trip.code,
      title: trip.title,
      titleEn: trip.titleEn,
      coverTitle: trip.coverTitle,
      coverTitleEn: trip.coverTitleEn,
      picPath: trip.picsPath,
      coverPic: trip.coverPic,
      countries: trip.countries,
      hashtags: trip.hashtags,
      issueDate: trip.issueDate,
    }),
  })
    .then((res) => {
      return checkAnswer(res);
    })
    .then((data) => {
      return data;
    });
};

export const getTrip = (tripCode) => {
  return fetch(`${BASE_URL_API}/trips/${tripCode}`, {
    method: "GET",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((res) => {
    return checkAnswer(res);
  });
};

export const getTrips = () => {
  return fetch(`${BASE_URL_API}/trips`, {
    method: "GET",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((res) => {
    return checkAnswer(res);
  });
};

export const updateTrip = (tripCode, trip) => {
  return fetch(`${BASE_URL_API}/trips/${tripCode}`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify({
      code: trip.code,
      title: trip.title,
      titleEn: trip.titleEn,
      coverTitle: trip.coverTitle,
      coverTitleEn: trip.coverTitleEn,
      picPath: trip.picsPath,
      coverPic: trip.coverPic,
      countries: trip.countries,
      hashtags: trip.hashtags,
      issueDate: trip.issueDate,
    }),
  }).then((res) => {
    return checkAnswer(res);
  });
};

export const deleteTrip = (tripCode) => {
  return fetch(`${BASE_URL_API}/trips/${tripCode}`, {
    method: "DELETE",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((res) => {
    return checkAnswer(res);
  });
};

// countries
export const createCountry = (country) => {
  return fetch(`${BASE_URL_API}/countries`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    credentials: "include",

    body: JSON.stringify({
      code: country.code,
      title: country.title,
      titleEn: country.titleEn,
      coverTitle: country.coverTitle,
      coverTitleEn: country.coverTitleEn,
      picture: country.picture,
      continent: country.continent,
      coverPic: country.coverPic,
    }),
  })
    .then((res) => {
      return checkAnswer(res);
    })
    .then((data) => {
      return data;
    });
};

export const getCountry = (currentCode) => {
  return fetch(`${BASE_URL_API}/countries/${currentCode}`, {
    method: "GET",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((res) => {
    return checkAnswer(res);
  });
};

export const getCountries = () => {
  return fetch(`${BASE_URL_API}/countries`, {
    method: "GET",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((res) => {
    return checkAnswer(res);
  });
};

export const updateCountry = (countryName, country) => {
  return fetch(`${BASE_URL_API}/countries/${countryName}`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify({
      code: countryName,
      title: country.title,
      titleEn: country.titleEn,
      coverTitle: country.coverTitle,
      coverTitleEn: country.coverTitleEn,
      picture: country.picture,
      continent: country.continent,
      coverPic: country.coverPic,
    }),
  }).then((res) => {
    return checkAnswer(res);
  });
};

export const deleteCountry = (countryCode) => {
  return fetch(`${BASE_URL_API}/countries/${countryCode}`, {
    method: "DELETE",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((res) => {
    return checkAnswer(res);
  });
};

// continents
export const createContinent = (continent) => {
  return fetch(`${BASE_URL_API}/continents`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    credentials: "include",

    body: JSON.stringify({
      code: continent.code,
      title: continent.title,
      titleEn: continent.titleEn,
      coverTitle: continent.coverTitle,
      coverTitleEn: continent.coverTitleEn,
      picture: continent.picture,
      countries: continent.countries,
    }),
  })
    .then((res) => {
      return checkAnswer(res);
    })
    .then((data) => {
      return data;
    });
};

export const getContinent = (continentCode) => {
  return fetch(`${BASE_URL_API}/continents/${continentCode}`, {
    method: "GET",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((res) => {
    return checkAnswer(res);
  });
};

export const getContinents = () => {
  return fetch(`${BASE_URL_API}/continents`, {
    method: "GET",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((res) => {
    return checkAnswer(res);
  });
};

export const updateContinent = (continentCode, continent) => {
  return fetch(`${BASE_URL_API}/continents/${continentCode}`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify({
      code: continent.code,
      title: continent.title,
      titleEn: continent.titleEn,
      coverTitle: continent.coverTitle,
      coverTitleEn: continent.coverTitleEn,
      picture: continent.picture,
      countries: continent.countries,
    }),
  }).then((res) => {
    return checkAnswer(res);
  });
};

export const deleteContinent = (continentCode) => {
  return fetch(`${BASE_URL_API}/continents/${continentCode}`, {
    method: "DELETE",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((res) => {
    return checkAnswer(res);
  });
};

// bestpics

export const createPic = (bestpic) => {
  return fetch(`${BASE_URL_API}/bestpics`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    credentials: "include",

    body: JSON.stringify({
      title: bestpic.title,
      titleEn: bestpic.titleEn,
      country: bestpic.country,
      tripCode: bestpic.trip,
      orientation: bestpic.orientation,
      hashtags: bestpic.hashtags,
      year: bestpic.year,
      link: bestpic.link,
    }),
  })
    .then((res) => {
      return checkAnswer(res);
    })
    .then((data) => {
      return data;
    });
};

export const getPic = (id) => {
  return fetch(`${BASE_URL_API}/bestpics/${id}`, {
    method: "GET",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((res) => {
    return checkAnswer(res);
  });
};

export const getPics = () => {
  return fetch(`${BASE_URL_API}/bestpics`, {
    method: "GET",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((res) => {
    return checkAnswer(res);
  });
};

export const updatePic = (id, bestpic) => {
  return fetch(`${BASE_URL_API}/bestpics/${id}`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify({
      title: bestpic.title,
      titleEn: bestpic.titleEn,
      country: bestpic.country,
      tripCode: bestpic.trip,
      orientation: bestpic.orientation,
      hashtags: bestpic.hashtags,
      year: bestpic.year,
      link: bestpic.link,
    }),
  }).then((res) => {
    return checkAnswer(res);
  });
};

export const deletePic = (id) => {
  return fetch(`${BASE_URL_API}/bestpics/${id}`, {
    method: "DELETE",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((res) => {
    return checkAnswer(res);
  });
};

// hashtags

export const createHashtag = (hashtag) => {
  return fetch(`${BASE_URL_API}/hashtags`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    credentials: "include",

    body: JSON.stringify({
      code: hashtag.code,
      coverTitle: hashtag.coverTitle,
      coverTitleEn: hashtag.coverTitleEn,
      coverPic: hashtag.coverPic,
      picture: hashtag.picture,
    }),
  })
    .then((res) => {
      return checkAnswer(res);
    })
    .then((data) => {
      return data;
    });
};

export const getHashtag = (hashtagCode) => {
  return fetch(`${BASE_URL_API}/hashtags/${hashtagCode}`, {
    method: "GET",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((res) => {
    return checkAnswer(res);
  });
};

export const getHashtags = () => {
  return fetch(`${BASE_URL_API}/hashtags`, {
    method: "GET",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((res) => {
    return checkAnswer(res);
  });
};

export const updateHashtag = (hashtagCode, hashtag) => {
  return fetch(`${BASE_URL_API}/hashtags/${hashtagCode}`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify({
      code: hashtag.code,
      coverTitle: hashtag.coverTitle,
      coverTitleEn: hashtag.coverTitleEn,
      coverPic: hashtag.coverPic,
      picture: hashtag.picture,
    }),
  }).then((res) => {
    return checkAnswer(res);
  });
};

export const deleteHashtag = (hashtagCode) => {
  return fetch(`${BASE_URL_API}/hashtags/${hashtagCode}`, {
    method: "DELETE",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((res) => {
    return checkAnswer(res);
  });
};

// pictags

export const createPictag = (pictag) => {
  return fetch(`${BASE_URL_API}/pictags`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    credentials: "include",

    body: JSON.stringify({
      code: pictag.code,
      title: pictag.title,
      titleEn: pictag.titleEn,
    }),
  })
    .then((res) => {
      return checkAnswer(res);
    })
    .then((data) => {
      return data;
    });
};

export const getPictag = (tagCode) => {
  return fetch(`${BASE_URL_API}/pictags/${tagCode}`, {
    method: "GET",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((res) => {
    return checkAnswer(res);
  });
};

export const getPictags = () => {
  return fetch(`${BASE_URL_API}/pictags`, {
    method: "GET",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((res) => {
    return checkAnswer(res);
  });
};

export const updatePictag = (tagCode, pictag) => {
  return fetch(`${BASE_URL_API}/hashtags/${tagCode}`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify({
      code: pictag.code,
      title: pictag.title,
      titleEn: pictag.titleEn,
    }),
  }).then((res) => {
    return checkAnswer(res);
  });
};

export const deletePictag = (tagCode) => {
  return fetch(`${BASE_URL_API}/pictags/${tagCode}`, {
    method: "DELETE",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((res) => {
    return checkAnswer(res);
  });
};
