import ContinentLink from "../ContinentLink/ContinentLink";

import worldPath from "../../images/siteImages/world.png";
import worldOrangePath from "../../images/siteImages/world_orange.png";
import NAPath from "../../images/siteImages/northamerica_grey.png";
import NAOrangePath from "../../images/siteImages/northamerica_orange.png";
import SAPath from "../../images/siteImages/southamerica.svg";
import SAOrangePath from "../../images/siteImages/southamerica_orange.png";
import asiaPath from "../../images/siteImages/asia.png";
import asiaOrangePath from "../../images/siteImages/asia_orange.png";
import africaPath from "../../images/siteImages/africa.svg";
import africaOrangePath from "../../images/siteImages/africa_orange.png";
import europePath from "../../images/siteImages/europe.png";
import europeOrangePath from "../../images/siteImages/europe_orange.png";
import australPath from "../../images/siteImages/australia.png";
import australOrangePath from "../../images/siteImages/australia_orange.png";
import russiaPath from "../../images/siteImages/russia.png";
import russiaOrangePath from "../../images/siteImages/russia_orange.png";

function ContinentsNavigation(props) {
  return (
    // <section className="navigation">
    <nav className="continents-menu">
      <ContinentLink
        inactiveImage={worldPath}
        activeImage={worldOrangePath}
        title="Все страны"
        link={`${props.path}allCountries`}
        isLarge={true}
      />

      <ContinentLink
        inactiveImage={NAPath}
        activeImage={NAOrangePath}
        title="Северная Америка"
        link={`${props.path}continents/NA`}
        isLarge={false}
      />

      <ContinentLink
        inactiveImage={SAPath}
        activeImage={SAOrangePath}
        title="Южная Америка"
        link={`${props.path}continents/SA`}
        isLarge={false}
      />

      <ContinentLink
        inactiveImage={asiaPath}
        activeImage={asiaOrangePath}
        title="Азия"
        link={`${props.path}continents/AS`}
        isLarge={false}
      />

      <ContinentLink
        inactiveImage={africaPath}
        activeImage={africaOrangePath}
        title="Африка"
        link={`${props.path}continents/AF`}
        isLarge={false}
      />

      <ContinentLink
        inactiveImage={europePath}
        activeImage={europeOrangePath}
        title="Европа"
        link={`${props.path}continents/EU`}
        isLarge={false}
      />

      <ContinentLink
        inactiveImage={australPath}
        activeImage={australOrangePath}
        title="Австралия и Океания"
        link={`${props.path}continents/AU`}
        isLarge={false}
      />

      <ContinentLink
        inactiveImage={russiaPath}
        activeImage={russiaOrangePath}
        title="Россия"
        link={`${props.path}continents/RU`}
        isLarge={true}
      />
    </nav>
    // </section>
  );
}

export default ContinentsNavigation;
