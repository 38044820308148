import React from "react";
import {
  chooseForm,
  tripForms,
  storyForms,
  countryForms,
  yearForms,
} from "../../utils/wordForms";

function Statistics(props) {
  function chooseWord(prop) {
    if (prop === "trips") {
      return tripForms;
    }
    if (prop === "countries") {
      return countryForms;
    }
    if (prop === "years") {
      return yearForms;
    }
    if (prop === "stories") {
      return storyForms;
    }
    return "";
  }

  return (
    <section className="statistics">
      <div className="statistics__item">
        <p className="statistics__number">{props.statistics1number}</p>
        <p className="statistics__text">
          {chooseForm(
            props.statistics1number,
            chooseWord(props.statistics1text)
          )}
        </p>
      </div>

      <div className="statistics__item">
        <p className="statistics__text">
          {chooseForm(
            props.statistics2number,
            chooseWord(props.statistics2text)
          )}
        </p>
        <p className="statistics__number">{props.statistics2number}</p>
      </div>
    </section>
  );
}

export default Statistics;
