import { Link } from "react-router-dom";
import { PIC_PATH, DB_base } from "../../utils/constants";

function ImagePopup(props) {
  return (
    <div
      className={
        props.isOpen ? "image-popup image-popup_opened" : "image-popup"
      }
    >
      <div className="image-popup__wrap">
        <p className="image-popup__comment" onClick={props.onClose}>
          Закрыть
        </p>
        <img
          className="image-popup__image"
          src={
            props.card.link ? props.card.link.replace(DB_base, PIC_PATH) : ""
          }
          alt={props.card.title}
        />
        <Link
          to={`../countries/${props.card.country}`}
          className="image-popup__comment"
          onClick={props.onClose}
        >
          {props.card.title}, {props.card.countryTitle}
        </Link>
      </div>
    </div>
  );
}
export default ImagePopup;
