import React from "react";
import "../../index.css";
import Footer from "../Footer/Footer";
import { Route, Switch } from "react-router-dom";
import { ProtectedRoute, EntranceRoute } from "../ProtectedRoute";


import Story from "../Story/Story";
import Country from "../Country/Country";
import Continent from "../Continent/Continent";
import Error from "../Error/Error";
import Home from "../Home/Home";
import AllYears from "../AllYears/AllYears";
import AllCountries from "../AllCountries/AllCounties";
import AllHashtags from "../AllHashtags/AllHashtags";
import Hashtag from "../Hashtag/Hashtag";
import BestPictures from "../BestPictures/BestPictures";
import Contacts from "../Contacts/Contacts";
import ImagePopup from "../ImagePopup/ImagePopup";
import DeletePopup from "../DeletePopup/DeletePopup";

//forms
import NewStoryForm from "../Forms/StoryForm/NewStoryForm";
import StoryForm from "../Forms/StoryForm/StoryForm";
import StoryEnglishForm from "../Forms/StoryEnglishForm/StoryEnglishForm";
import NewTripForm from "../Forms/TripForm/NewTripForm";
import ContinentForm from "../Forms/ContinentForm/ContinentForm";
import NewContinentForm from "../Forms/ContinentForm/NewContinentForm";
import NewCountryForm from "../Forms/CountryForm/NewCountryForm";
import CountryForm from "../Forms/CountryForm/CountryForm";
import TripForm from "../Forms/TripForm/TripForm";
import NewHashtagForm from "../Forms/HashtagForm/NewHashtagForm";
import HashtagForm from "../Forms/HashtagForm/HashtagForm";
import NewBestPicsForm from "../Forms/BestPicsForm/NewBestPicsForm";
import BestPicsForm from "../Forms/BestPicsForm/BestPicsForm";
import NewPictagForm from "../Forms/PictagForm/NewPictagForm";
import PictagForm from "../Forms/PictagForm/PictagForm"
import FormError from "../FormError/FormError";
import Register from "../Forms/Register/Register";
import Login from "../Forms/Login/Login";

//admins
import AdminContinents from "../Admins/AdminContinents/AdminContinents";
import AdminCountry from "../Admins/AdminCountry/AdminCountry";
import AdminTrip from "../Admins/AdminTrip/AdminTrip";
import AdminHashtags from "../Admins/AdminHashtags/AdminHashtags";
import AdminBestPics from "../Admins/AdminBestPics/AdminBestPics";
import AdminPictags from "../Admins/AdminPictags/AdminPictags";

import { 
  getTrips, 
  getCountries, 
  getStories, 
  getContinents, 
  getHashtags, 
  getPics,
  getPictags 

} from "../../utils/API";

import { CountriesContext } from "../../context/CountriesContext";
import { TripsContext } from "../../context/TripsContext";
import { StoriesContext } from "../../context/StoriesContext";
import { ContinentsContext } from "../../context/ContinentsContext"; 
import { HashtagsContext } from "../../context/HashtagsContext"; 
import { BestPicsContext } from "../../context/BestPicsContext"
import { PictagsContext } from "../../context/PictagsContext"
import { LoggedContext } from "../../context/LoggedContext";


function App() {
  const [trips, setTrips] = React.useState([]);
  const [countries, setCountries] = React.useState([]);
  const [stories, setStories] = React.useState([]);
  const [continents, setContinents] = React.useState([]);
  const [hashtags, setHashtags] = React.useState([]);
  const [bestpics, setBestpics] = React.useState([]);
  const [pictags, setPictags] = React.useState([]);


  const [isError, setError] = React.useState(false);
  const [isAPIErr, setAPIErr] = React.useState(false);
  const [isDelOpen, setDelOpen] = React.useState(false);
  const [isDelAllowed, setDelAllowed] = React.useState(false);
  const [errMsg, setErrMsg] = React.useState("что-то тут не так");
  const [selectedCard, setSelectedCard] = React.useState({
    source: "",
    country: "",
    title: "",
    // isOpen: false,
  });
  const [imageOpen, setImageOpen] = React.useState("");

  const [loggedIn, setLoggedStatus] = React.useState(
    JSON.parse(localStorage.getItem("logged"))
  );

  // const [currentUser, setCurrentUser] = React.useState(
  //   localStorage.getItem("currentUser")
  // );


  function handleLogin() {
    setLoggedStatus(true);
    localStorage.setItem("logged", "true");
  }

  function handleLogout() {
        console.log("logout");
        setLoggedStatus(false);
        localStorage.clear();
        localStorage.setItem("logged", "false");
  }

  // function tokenCheck() {
  //   checkToken()
  //     .then((data) => {
  //       setLoggedStatus(true);
  //       localStorage.setItem("logged", "true");
  //     })
  //     .catch((err) => console.log(err));
  // }


  function handleDelOpen() {
    setDelOpen(true);
  }
  function handleDelClose() {
    setDelOpen(false);
  }
  function handleAllowDel() {
    setDelAllowed(true);
    setDelOpen(false);
  }
  function restrictDel() {
    setDelAllowed(false);
  }

  // React.useEffect(() => {console.log(isDelAllowed)}, [isDelAllowed])

  function handleError() {
    // setError(true);
    setError(false);
  }
  function handleAPIErr(err) {
    setAPIErr(true);
    setErrMsg(err);
  }

  function handleAPIErrClose() {
    setAPIErr(false);
  }

  function closeError() {
    setError(false);
  }

  const handleCardClick = (card) => {
    setSelectedCard(card);
    setImageOpen(true);
  };

  React.useEffect(() => {
    getCountries()
      .then((res) => {
        setCountries(Array.from(res.data));
      })
      .catch((err) => {
        console.log(err);
      });

    getContinents()
      .then((res) => {
        setContinents(Array.from(res.data));
      })
      .catch((err) => {
        console.log(err);
      });

    getTrips()
    .then((res) => {
      setTrips(Array.from(res.data));
    })
    .catch((err) => {
      console.log(err);
    });

    getStories()
    .then((res) => {
      setStories(Array.from(res.data));
    })
    .catch((err) => {
      console.log(err);
    });
    getHashtags()
    .then((res) => {
      setHashtags(Array.from(res.data));
    })
    .catch((err) => {
      console.log(err);
    });
    getPics()
    .then((res) => {
      setBestpics(Array.from(res.data));
    })
    .catch((err) => {
      console.log(err);
    });
    getPictags()
    .then((res) => {
      setPictags(Array.from(res.data));
    })
    .catch((err) => {
      console.log(err);
    });
  }, []);



  
// React.useEffect(() => console.log(countries), [countries])


  React.useEffect(() => {
    // console.log(selectedCard, imageOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCard]);

  const closeCard = () => {
    setSelectedCard({ source: "", country: "", title: "" });
    setImageOpen(false);
  };


  return (

    <LoggedContext.Provider value={loggedIn}>
    <ContinentsContext.Provider value={continents}>
    <CountriesContext.Provider value={countries}>
    <TripsContext.Provider value={trips}>
    <StoriesContext.Provider value={stories}>
    <HashtagsContext.Provider value={hashtags}>
    <BestPicsContext.Provider value={bestpics}>
    <PictagsContext.Provider value={pictags}>



    <div className="page">
      <Switch>
        <Route exact path="/">
          <Home/>
        </Route>
        <Route path="/allYears">
          <AllYears />
        </Route>
        <Route path="/allCountries">
          <AllCountries />
        </Route>
        <Route path="/allHashtags">
          <AllHashtags />
        </Route>
        <Route path="/bestPictures">
          <BestPictures onCardClick={handleCardClick} />
        </Route>
        <Route path="/contacts">
          <Contacts onCardClick={handleCardClick}/>
        </Route>


        <Route path="/stories/:storyCode">
          <Story handleNotFound={handleError} handleFind={closeError} />
        </Route>
        <Route path="/countries/:countryCode">
          <Country handleNotFound={handleError} handleFind={closeError} />
        </Route>
        <Route path="/continents/:continentCode">
          <Continent handleNotFound={handleError} handleFind={closeError} />
        </Route>
        <Route path="/hashtags/:hashtagCode">
          <Hashtag handleNotFound={handleError} handleFind={closeError} />
        </Route>
        <Route path="/bestpics/:tagCode">
          <BestPictures  handleNotFound={handleError} handleFind={closeError} onCardClick={handleCardClick}/>
        </Route>


        <Route path="/register">
          <Register />
        </Route>
        {/* <Route path="/signin">
          <Login onLogin={handleLogin}/>
        </Route> */}
        <EntranceRoute
                path="/signin"
                component={Login}
                onLogin={handleLogin}
        ></EntranceRoute>
        <EntranceRoute
                path="/register"
                component={Register}
                onLogin={handleLogin}
        ></EntranceRoute>


        {/* формы новые */}

        <ProtectedRoute 
          path="/admin/forms/story/add"
          component = {NewStoryForm}>
        </ProtectedRoute>
        <ProtectedRoute 
          path="/admin/forms/trip/add"
          component = {NewTripForm}
          onErr={handleAPIErr}
          >
        </ProtectedRoute>
        <ProtectedRoute 
          path="/admin/forms/country/add"
          component = {NewCountryForm}
          onErr={handleAPIErr}
          >
        </ProtectedRoute>
        <ProtectedRoute 
          path="/admin/forms/continent/add"
          component = {NewContinentForm}
          onErr={handleAPIErr}
          >
        </ProtectedRoute>
        <ProtectedRoute 
          path="/admin/forms/hashtag/add"
          component = {NewHashtagForm}
          onErr={handleAPIErr}
          >
        </ProtectedRoute>
        <ProtectedRoute 
          path="/admin/forms/bestpic/add"
          component = {NewBestPicsForm}
          onErr={handleAPIErr}
          >
        </ProtectedRoute>
        <ProtectedRoute 
          path="/admin/forms/pictag/add"
          component = {NewPictagForm}
          onErr={handleAPIErr}
          >
        </ProtectedRoute>

        {/* формы редактирования */}
        <Route 
          path="/admin/forms/continents/:currentCode">
          <ContinentForm
          handleNotFound={handleError}
          handleFind={closeError}
          isDelAllowed={isDelAllowed}
          onDeleteClick={handleDelOpen}
          restrictDel={restrictDel}
          />
        </Route>

        <Route 
          path="/admin/forms/countries/:currentCode">
          <CountryForm
          handleNotFound={handleError}
          handleFind={closeError}
          isDelAllowed={isDelAllowed}
          onDeleteClick={handleDelOpen}
          restrictDel={restrictDel}
          />
          </Route>        
          
          <Route 
          path="/admin/forms/stories/:currentCode">
          <StoryForm
          handleNotFound={handleError}
          handleFind={closeError}
          isDelAllowed={isDelAllowed}
          onDeleteClick={handleDelOpen}
          restrictDel={restrictDel}
          />
          </Route>

          <Route 
          path="/admin/forms/stories_eng/:currentCode">
          <StoryEnglishForm
          handleNotFound={handleError}
          handleFind={closeError}
          isDelAllowed={isDelAllowed}
          onDeleteClick={handleDelOpen}
          restrictDel={restrictDel}
          />
          </Route>
          
          <Route 
          path="/admin/forms/trips/:currentCode">
          <TripForm
          handleNotFound={handleError}
          handleFind={closeError}
          isDelAllowed={isDelAllowed}
          onDeleteClick={handleDelOpen}
          restrictDel={restrictDel}
          />
          </Route>
          
          <Route 
          path="/admin/forms/hashtags/:currentCode">
          <HashtagForm
          handleNotFound={handleError}
          handleFind={closeError}
          isDelAllowed={isDelAllowed}
          onDeleteClick={handleDelOpen}
          restrictDel={restrictDel}
          />
          </Route>
          
          <Route 
          path="/admin/forms/bestpics/:currentCode">
          <BestPicsForm
          handleNotFound={handleError}
          handleFind={closeError}
          isDelAllowed={isDelAllowed}
          onDeleteClick={handleDelOpen}
          restrictDel={restrictDel}
          />
          </Route>
          
          <Route 
          path="/admin/forms/pictags/:currentCode">
          <PictagForm
          handleNotFound={handleError}
          handleFind={closeError}
          isDelAllowed={isDelAllowed}
          onDeleteClick={handleDelOpen}
          restrictDel={restrictDel}
          />
          </Route>

        {/* <ProtectedRoute 
          path="/admin/forms/continents/:currentCode"
          component= {ContinentForm}
          handleNotFound={handleError}
          handleFind={closeError}
          isDelAllowed={isDelAllowed}
          onDeleteClick={handleDelOpen}
          restrictDel={restrictDel}
          >
        </ProtectedRoute>
        <ProtectedRoute 
          path="/admin/forms/countries/:currentCode"
          component= {CountryForm}
          handleNotFound={handleError}
          handleFind={closeError}
          isDelAllowed={isDelAllowed}
          onDeleteClick={handleDelOpen}
          restrictDel={restrictDel}
          >
        </ProtectedRoute>
        <ProtectedRoute 
          path="/admin/forms/stories/:currentCode"
          component= {StoryForm}
          handleNotFound={handleError}
          handleFind={closeError}
          isDelAllowed={isDelAllowed}
          onDeleteClick={handleDelOpen}
          restrictDel={restrictDel}
          >
        </ProtectedRoute>
        <ProtectedRoute 
          path="/admin/forms/stories_eng/:currentCode"
          component= {StoryEnglishForm}
          handleNotFound={handleError}
          handleFind={closeError}
          isDelAllowed={isDelAllowed}
          onDeleteClick={handleDelOpen}
          restrictDel={restrictDel}
          >
        </ProtectedRoute>
        <ProtectedRoute 
          path="/admin/forms/trips/:currentCode"
          component= {TripForm}
          handleNotFound={handleError}
          handleFind={closeError}
          isDelAllowed={isDelAllowed}
          onDeleteClick={handleDelOpen}
          restrictDel={restrictDel}
          >
        </ProtectedRoute>
        <ProtectedRoute 
          path="/admin/forms/hashtags/:currentCode"
          component= {HashtagForm}
          handleNotFound={handleError}
          handleFind={closeError}
          isDelAllowed={isDelAllowed}
          onDeleteClick={handleDelOpen}
          restrictDel={restrictDel}
          >
        </ProtectedRoute>
        <ProtectedRoute 
          path="/admin/forms/bestpics/:currentCode"
          component= {BestPicsForm}
          handleNotFound={handleError}
          handleFind={closeError}
          isDelAllowed={isDelAllowed}
          onDeleteClick={handleDelOpen}
          restrictDel={restrictDel}
          >
        </ProtectedRoute>
        <ProtectedRoute 
          path="/admin/forms/pictags/:currentCode"
          component= {PictagForm}
          handleNotFound={handleError}
          handleFind={closeError}
          isDelAllowed={isDelAllowed}
          onDeleteClick={handleDelOpen}
          restrictDel={restrictDel}
          >
        </ProtectedRoute> */}

        {/* административные страницы */}
        {/* <ProtectedRoute 
          path="/admin/trips/:currentCode"
          component = {AdminTrip}
          >
        </ProtectedRoute>
        <ProtectedRoute 
          path="/admin/countries/:currentCode"
          component = {AdminCountry}
          >
        </ProtectedRoute> */}

        <Route 
          path="/admin/trips/:currentCode">
          <AdminTrip/>
        </Route>
        <Route 
          path="/admin/countries/:currentCode">
          <AdminCountry/>
        </Route>

        <ProtectedRoute 
          path="/admin/hashtags"
          component = {AdminHashtags}
          >
        </ProtectedRoute>
        <ProtectedRoute 
          path="/admin/bestpics"
          component = {AdminBestPics}
          >
        </ProtectedRoute>
        <ProtectedRoute 
          path="/admin/pictags"
          component = {AdminPictags}
          >
        </ProtectedRoute>
        <ProtectedRoute 
          path="/admin"
          component = {AdminContinents}
          onLogout={handleLogout}
          >
        </ProtectedRoute>

      </Switch>

      <Error isError={isError} onClick={closeError} onClose={closeError} />
      <FormError
        isOpen={isAPIErr}
        message={errMsg}
        onClose={handleAPIErrClose}
      />
      <DeletePopup
        isOpen={isDelOpen}
        onClose={handleDelClose}
        onYes={handleAllowDel}
        onNo={handleDelClose}
      />
      <ImagePopup card={selectedCard} isOpen={imageOpen} onClose={closeCard} />
      <Footer />
    </div>
    </PictagsContext.Provider>
    </BestPicsContext.Provider>
    </HashtagsContext.Provider>
    </StoriesContext.Provider>
    </TripsContext.Provider>
    </CountriesContext.Provider >
    </ContinentsContext.Provider>
    </LoggedContext.Provider>

  );
}

export default App;

// onYes={handleDelete}
