import React from "react";
import Input from "../../Forms/Input/Input";
import FormButton from "../FormButton/FormButton";
import FormPage from "../FormPage/FormPage";
import { useParams } from "react-router-dom";
import { getStory, updateStory } from "../../../utils/API";
import { checkFormValidity } from "../../../utils/functions";
import { useHistory } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import {PIC_PATH, DB_base} from "../../../utils/constants"


// import { regexLatin } from "../../../utils/regexFront";

function StoryEnglishForm(props) {
  const { currentCode } = useParams();
  const history = useHistory();

  const [storyTitleEn, setStoryTitle] = React.useState("");
  const [coverTitleEn, setCoverTitle] = React.useState("");
  const [storyBodyEn, setStoryBody] = React.useState([]);
  const [story, setStory] = React.useState([]);
  const [formValid, setFormValidity] = React.useState(false);

  function handleSubmit(e) {
    e.preventDefault();
    updateStory(currentCode, story)
      .then((res) => {
        history.goBack();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleClose(e) {
    history.goBack();
  }

  React.useEffect(() => {
    getStory(currentCode)
      .then((res) => {
        res.data === null || res.data === undefined
          ? props.handleNotFound()
          : setStory(res.data);
        setStoryTitle({ value: res.data.titleEn, validity: true });
        setCoverTitle({ value: res.data.coverTitleEn, validity: true });
        setStoryBody({ value: res.data.bodyEn, validity: true });
      })
      .catch((err) => {
        err === 404 ? props.handleNotFound() : console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCode]);

  React.useEffect(() => {
    setStory({
      country: story.country,
      trip: story.trip,
      dayNumber: story.dayNumber,
      date: story.date,
      title: story.title,
      titleEn: storyTitleEn.value,
      coverTitle: story.coverTitle,
      coverTitleEn: coverTitleEn.value,
      picsPath: story.picsPath,
      coverPic: story.coverPic,
      body: story.storyBody,
      bodyEn: storyBodyEn.value,
    });

    const inputs = [storyTitleEn, coverTitleEn, storyBodyEn];

    setFormValidity(checkFormValidity(inputs));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storyTitleEn, coverTitleEn, storyBodyEn]);

  return (
    <FormPage name={`${story.title} english`}>
      <div className="form__rowwrap form__buttonrow">
        <FormButton handleClick={handleClose} title="Закрыть" isActive={true} />
        <FormButton
          handleClick={handleSubmit}
          title="Обновить"
          isActive={formValid}
        />
      </div>

      <div className="form__rowwrap">
        <Input
          title="Название дня"
          select={false}
          type="text"
          id="storyTitle"
          name="storyTitle"
          size="XL"
          placeholder="50 символов"
          value={storyTitleEn.value}
          item={storyTitleEn}
          setItem={setStoryTitle}
          required={true}
          // pattern={regexLatin}
        />

        <Input
          title="Краткое название"
          select={false}
          type="text"
          id="coverTitle"
          name="coverTitle"
          size="M"
          placeholder="20 символов"
          value={coverTitleEn.value}
          item={coverTitleEn}
          setItem={setCoverTitle}
          required={true}
        />
      </div>

      <div className="form__rowwrap">
        <Input
          body={true}
          title="Рассказ"
          select={false}
          type="string"
          id="storyBody"
          name="storyBody"
          size="M"
          placeholder="пиши сюда"
          value={storyBodyEn.value}
          item={storyBodyEn}
          setItem={setStoryBody}
          required={false}
        />
      </div>

      <section className="story">{ReactHtmlParser(storyBodyEn.value ? storyBodyEn.value.replaceAll(DB_base,PIC_PATH): "")}</section>
    </FormPage>
  );
}

export default StoryEnglishForm;
