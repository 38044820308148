import React from "react";
import Input from "../../Forms/Input/Input";
import FormButton from "../FormButton/FormButton";
import FormPage from "../FormPage/FormPage";
import BestPicExample from "../BestPicExample/BestPicExample";
import { useHistory } from "react-router-dom";
import {
  getPic,
  updatePic,
  deletePic,
  getCountries,
  getTrips,
  getPictags,
} from "../../../utils/API";
import { checkFormValidity } from "../../../utils/functions";
import { useParams } from "react-router-dom";

function BestPicsForm(props) {
  const { currentCode } = useParams();
  const [picLink, setLink] = React.useState({});
  const [picCountry, setPicCountry] = React.useState({});
  const [picTrip, setTrip] = React.useState({});
  const [HV, setHV] = React.useState({});
  const [picTitle, setPicTitle] = React.useState({});
  const [picTitleEn, setPicTitleEn] = React.useState({});
  const [tags, setTags] = React.useState({});
  const [year, setYear] = React.useState({});
  const [countries, setCountries] = React.useState([]);
  const [trips, setTrips] = React.useState([]);
  const [pictags, setPicTags] = React.useState([]);
  const [bestpic, setBestpic] = React.useState({});
  const [formValid, setFormValidity] = React.useState(false);

  const history = useHistory();

  function handleSubmit(e) {
    e.preventDefault();
    updatePic(currentCode, bestpic)
      .then((res) => {
        history.goBack();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleClose(e) {
    history.goBack();
  }

  function handleDelete(e) {
    e.preventDefault();
    props.isDelAllowed
      ? getPic(currentCode)
          .then((res) =>
            res.data === null || res.data === undefined
              ? props.handleNotFound()
              : deletePic(res.data._id).then(() => {
                  history.goBack();
                })
          )
          .then(() => {
            props.restrictDel();
          })

          .catch((err) => {
            console.log(err);
          })
      : props.onDeleteClick();
  }

  function makeNewCountry() {
    history.push("/admin/forms/country/add");
  }

  function makeNewTrip() {
    history.push("/admin/forms/trip/add");
  }

  function makeNewTag() {
    history.push("/admin/forms/pictag/add");
  }

  React.useEffect(() => {
    getCountries()
      .then((res) => {
        setCountries(Array.from(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
    getTrips()
      .then((res) => {
        setTrips(Array.from(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
    getPictags().then((res) => {
      setPicTags(Array.from(res.data));
    });
  }, []);

  React.useEffect(() => {
    getPic(currentCode)
      .then((res) => {
        res.data === null || res.data === undefined
          ? props.handleNotFound()
          : setBestpic(res.data);
        setPicCountry({ value: res.data.country, validity: true });
        setTrip({ value: res.data.tripCode, validity: true });
        setLink({ value: res.data.link, validity: true });
        setPicTitleEn({ value: res.data.titleEn, validity: true });
        setPicTitle({ value: res.data.title, validity: true });
        setHV({ value: res.data.orientation, validity: true });
        setTags({ value: res.data.hashtags, validity: true });
        setYear({ value: res.data.year, validity: true });
      })
      .then(() => props.restrictDel())
      .catch((err) => {
        err === 404 ? props.handleNotFound() : console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCode]);

  React.useEffect(() => {
    setBestpic({
      country: picCountry.value,
      trip: picTrip.value,
      orientation: HV.value,
      title: picTitle.value,
      titleEn: picTitleEn.value,
      hashtags: tags.value,
      year: year.value,
      link: picLink.value,
    });

    const inputs = [
      picCountry,
      picTrip,
      HV,
      picTitle,
      picTitleEn,
      tags,
      year,
      picLink,
    ];

    setFormValidity(checkFormValidity(inputs));
  }, [picCountry, picTrip, HV, picTitle, picTitleEn, tags, year, picLink]);

  // React.useEffect(() => {
  //   console.log (formValid, picCountry.validity,
  //     picTrip.validity,
  //     HV.validity,
  //     picTitle.validity,
  //     picTitleEn.validity,
  //     tags.validity,
  //     year.validity,
  //     picLink.validity )
  // }, [    picCountry,
  //   picTrip,
  //   HV,
  //   picTitle,
  //   picTitleEn,
  //   tags,
  //   year,
  //   picLink
  // ])

  return (
    <FormPage name={picTitle.value}>
      <p className="form__message">
        Удаление {props.isDelAllowed ? "разрешено" : "запрещено"}
      </p>
      <div className="form__rowwrap form__buttonrow">
        <FormButton
          danger={true}
          handleClick={handleDelete}
          title="Удалить"
          isActive={true}
        />
        <FormButton handleClick={handleClose} title="Закрыть" isActive={true} />
        <FormButton
          handleClick={handleSubmit}
          title="Обновить"
          isActive={formValid}
        />
      </div>

      <div className="form__rowwrap">
        <Input
          title="Фото"
          select={false}
          type="string"
          id="coverPic"
          name="coverPic"
          size="XL"
          placeholder="имя файла"
          value={picLink.value}
          item={picLink}
          setItem={setLink}
          required={false}
        />
      </div>
      <div className="form__rowwrap">
        <Input
          title="Страна"
          select={true}
          selectionArray={countries}
          type="text"
          id="country"
          name="country"
          size="M"
          placeholder=""
          value={picCountry.value}
          item={picCountry}
          setItem={setPicCountry}
          required={true}
          handleNew={makeNewCountry}
        />

        <Input
          title="Код поездки"
          select={true}
          selectionArray={trips}
          // handleNew={handler}
          type="string"
          id="trip"
          name="trip"
          size="S"
          placeholder=""
          value={picTrip.value}
          item={picTrip}
          setItem={setTrip}
          required={false}
          handleNew={makeNewTrip}
        />
        <Input
          title="H or V"
          select={false}
          type="text"
          id="HV"
          name="HV"
          size="XS"
          placeholder="H or V"
          value={HV.value}
          item={HV}
          setItem={setHV}
          required={true}
        />
        <Input
          title="Год"
          select={false}
          type="number"
          id="year"
          name="year"
          size="S"
          placeholder="год"
          value={year.value}
          item={year}
          setItem={setYear}
          required={true}
        />
      </div>
      <div className="form__rowwrap">
        <Input
          title="Название"
          select={false}
          type="text"
          id="picTitle"
          name="picTitle"
          size="XL"
          placeholder="50 символов"
          value={picTitle.value}
          item={picTitle}
          setItem={setPicTitle}
          required={true}
        />
      </div>

      <div className="form__rowwrap">
        <Input
          title="Title"
          select={false}
          type="text"
          id="picTitleEn"
          name="picTitleEn"
          size="XL"
          placeholder="50 символов"
          value={picTitleEn.value}
          item={picTitleEn}
          setItem={setPicTitleEn}
          required={true}
        />
      </div>

      <div className="form__rowwrap">
        <Input
          title="Picture Tags"
          select={false}
          type="text"
          id="tripHashtags"
          name="tripHashtags"
          size="L"
          placeholder=""
          value={tags.value}
          item={tags}
          setItem={setTags}
          required={false}
          // handleNew={makeNewTag}
        />
        <p className="form__reference">
          {Array.from(pictags).map((tag) => `${tag.code} `)}
        </p>
      </div>
      <div className="form__rowwrap form__buttonrow">
        <FormButton
          handleClick={makeNewTag}
          title="Добавить тэг"
          isActive={true}
        />
      </div>

      <BestPicExample link={picLink.value} />
    </FormPage>
  );
}

export default BestPicsForm;
