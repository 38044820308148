import React from "react";
import ListItem from "../ListItem/ListItem";
import FormButton from "../../Forms/FormButton/FormButton";
import FormLink from "../../Forms/FormLink/FormLink";
import { getTrips, getHashtags } from "../../../utils/API";
import { BASE_URL } from "../../../utils/constants";
import { useHistory } from "react-router-dom";

function AdminHashtags(props) {
  const history = useHistory();
  const [hashtags, setHashtags] = React.useState([]);
  const [trips, setTrips] = React.useState([]);

  function handleClose(e) {
    history.goBack();
  }

  React.useEffect(() => {
    getHashtags()
      .then((res) => setHashtags(Array.from(res.data)))
      .catch((err) => {
        console.log(err);
      });

    getTrips()
      .then((res) => setTrips(Array.from(res.data)))
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="admin__page">
      <h2 className="admin__title">Тематики поездок</h2>
      <div className="form__rowwrap form__buttonrow">
        <FormButton handleClick={handleClose} title="Закрыть" isActive={true} />

        <FormLink
          base={BASE_URL}
          route={`/admin/forms/hashtag/add`}
          name="Добавить"
        />
      </div>

      <div className="admin__table">
        {Array.from(hashtags).map((hashtag, i) => {
          return (
            <ListItem
              key={hashtag.code}
              title={hashtag.code}
              content={Array.from(trips).filter((trip) =>
                trip.hashtags.includes(hashtag.code)
              )}
              linkbase={`/admin/trips/`}
              updatelink={`/admin/forms/hashtags/${hashtag.code}`}
            />
          );
        })}
      </div>
    </div>
  );
}

export default AdminHashtags;
