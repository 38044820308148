import React from "react";
import ListItem from "../ListItem/ListItem";
import FormLink from "../../Forms/FormLink/FormLink";
import { useParams } from "react-router-dom";
import { getCountry, getTrips, getStories } from "../../../utils/API";
import { BASE_URL } from "../../../utils/constants";

function AdminCountry(props) {
  const { currentCode } = useParams();
  const [country, setCountry] = React.useState({});
  const [trips, setTrips] = React.useState({});
  const [countryStories, setCountryStories] = React.useState({});
  const [countryTrips, setCountryTrips] = React.useState({});

  React.useEffect(() => {
    console.log(currentCode);
    getCountry(currentCode)
      .then((res) => {
        console.log(res.data);
        setCountry(res.data);
      })
      .then(() =>
        getTrips().then((res) => {
          setTrips(res.data);
        })
      )
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setCountryTrips(
      Array.from(trips).filter((trip) => trip.countries.includes(country.code))
    );
    getStories()
      .then((res) => {
        console.log(res.data);
        setCountryStories(Array.from(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [country, trips]);

  return (
    <div className="admin__page">
      <h2 className="admin__title">{country.title}</h2>

      <div className="form__rowwrap form__buttonrow">
        <FormLink
          base={BASE_URL}
          route={`/admin/forms/countries/${country.code}`}
          name="Редактировать"
        />

        <FormLink
          base={BASE_URL}
          route="/admin/forms/trip/add"
          name="Новая поездка"
        />

        <FormLink base={BASE_URL} route="/admin/" name="Обратно" />
      </div>

      <div className="admin__table">
        {Array.from(countryTrips).map((trip, i) => {
          return (
            <ListItem
              key={trip.code}
              title={trip.title}
              content={Array.from(countryStories).filter(
                (story) => story.trip === trip.code
              )}
              linkbase={`/admin/forms/stories/`}
              updatelink={`/admin/trips/${trip.code}`}
            />
          );
        })}
      </div>
    </div>
  );
}

export default AdminCountry;
