import React from "react";
import Header from "../Header/Header";
import ContinentsNavigation from "../ContinentsNavigation/ContinentsNavigation";
import { useParams } from "react-router-dom";

import { CountriesContext } from "../../context/CountriesContext";
import { TripsContext } from "../../context/TripsContext";
import { StoriesContext } from "../../context/StoriesContext";
import { ContinentsContext } from "../../context/ContinentsContext";

import Statistics from "../Statistics/Statistics";
import Photogrid from "../Photo-grid/Photo-grid";
import { PIC_PATH, DB_base } from "../../utils/constants";

function Continent(props) {
  const { continentCode } = useParams();
  const [continent, setContinent] = React.useState("");
  const [continentCountries, setContinentCountries] = React.useState("");
  const [countriesNumber, setCountriesNumber] = React.useState("");
  const [fullCountries, setFullCountries] = React.useState("");
  const [tripsNumber, setTripsNumber] = React.useState("");

  const trips = React.useContext(TripsContext);
  const countries = React.useContext(CountriesContext);
  const stories = React.useContext(StoriesContext);
  const continents = React.useContext(ContinentsContext);
  const picPath = continent.picture
    ? continent.picture.replace(DB_base, PIC_PATH)
    : "";

  React.useEffect(() => {
    // console.log(continentCode);
    if (continents.find((continent) => continent.code === continentCode)) {
      setContinent(
        continents.find((continent) => continent.code === continentCode)
      );
      setContinentCountries(
        countries.filter((country) => country.continent === continentCode)
      );
      props.handleFind();
    } else {
      props.handleNotFound();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [continentCode, continents, countries]);

  // React.useEffect(() => {
  //   console.log(continent);
  // }, [continent]);

  React.useEffect(() => {
    setCountriesNumber(continentCountries.length);

    const sortedList = Array.from(continentCountries).sort((a, b) =>
    a.title > b.title ? 1 : -1
  );


    setFullCountries(
      Array.from(sortedList).map((country) => gatherTripData(country))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [continentCountries]);

  React.useEffect(() => {
    // console.log(fullCountries);
    let x = 0;
    setTripsNumber(
      Array.from(fullCountries)
        .map((i) => (x += i.trips.length), x)
        .reverse()[0]
    );
  }, [fullCountries]);

  function gatherTripData(country) {
    const countryTrips = trips.filter((trip) =>
      trip.countries.includes(country.code)
    );
    const countryStories = stories.filter(
      (story) => story.country === country.code
    );

    const fullCountry = {
      coverPic: country.coverPic,
      coverTitle: country.coverTitle,
      trips: countryTrips,
      stories: countryStories,
      code: country.code,
    };
    return fullCountry;
  }

  return (
    <div className="country">
      <Header headerImage={picPath} title={continent.title} />
      <ContinentsNavigation path={"../"} />
      <Statistics
        statistics1number={tripsNumber}
        statistics2number={countriesNumber}
        statistics1text="trips"
        statistics2text="countries"
      />
      <Photogrid
        cards={Array.from(fullCountries)}
        // key={section.code}
        // id={section.code}
        path={"../countries/"}
        // это про заполнение карточки, true/false
        date={false}
        month={false}
        yearMonth={false}
        trips={true}
        stories={true}
      />
    </div>
  );
}

export default Continent;
